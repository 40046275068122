import { Opacity, Padding } from "@mui/icons-material";
import {
  bgcolor,
  border,
  borderColor,
  borderRadius,
  color,
  display,
  margin,
  padding,
  width,
} from "@mui/system";

const styles = (theme) => ({
  container: { display: "flex", flexDirection: "column", gap: 4 },
  filterBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width: 1010px)": {
      flexDirection: "column",
      gap: 2,
    },
  },
  filterGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 2,
  },
  filterGroupItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
  },
  filterTypography: {
    textWrap: "nowrap",
    //fontWeight: 500,
  },
  formControl: {
    display: "flex",
    width: "120px",
    minWidth: "120px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "primary",
      color: "primary",
    },
    "& .MuiInputLabel-root": {
      color: "primary",
    },
    svg: {
      color: "primary",
    },
    input: {
      color: "primary",
    },
  },
  labelCaption: {
    //opacity: 0.6,
    fontSize: "13px",
    fontWeight: 500,
  },
  filterMenuRadioRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  filterMenuRadioTypography: { fontSize: "15px", textWrap: "nowrap" },
  filterMenuTooltip: { width: "200px" },
  iconInfoOutlined: { width: "12px", mb: "4px", ml: "2px" },

  mobileDrawerPaper: {
    backgroundColor: "background.default",
    height: "100%",
    borderRadius: 0,
  },
  mobileDrawerBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 3,
    margin: "20px 10px",
  },
  mobileDrawerHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  mobileDrawerFormControl: {
    width: "280px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "primary",
      color: "primary",
    },
    "& .MuiInputLabel-root": {
      color: "primary",
    },
    svg: {
      color: "primary",
    },
    input: {
      color: "primary",
    },
  },
  filterMenuRadioGroup: {
    display: "flex",
    width: 1,
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
    flexDirection: "column",
  },
  filterMenuRadioRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  filterMenuRadioTypography: { fontSize: "15px" },
  filterMenuTooltip: { width: "200px" },
  filterMenuRadioPaper: {
    display: "flex",
    width: "100%",
    padding: "8px",
    borderRadius: "10px",
  },
  containerAlertAtaque: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    borderRadius: "100%",
    //margin: "0 15px",
    animation: `${
      theme.palette.mode === "dark" ? "myEffectDark" : "myEffectLight"
    }
     1.5s linear 0s infinite normal none`,
    "@keyframes myEffectDark": {
      "0%, 100%": { color: "#FA541C", fontWeight: "600" },
      "50%": { color: "#ffffff", fontWeight: "600" },
    },
    "@keyframes myEffectLight": {
      "0%, 100%": { color: "#FA541C", fontWeight: "600" },
      "50%": { color: "#000000", fontWeight: "600" },
    },
  },
  containerAlert: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    borderRadius: "100%",
    margin: "0 5px",
    color: "#00A76F",
  },
  filterGroupAlert: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 2,
    width: "23%",
    justifyContent: "end",
  },
  filterGroupAlertMobile: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 2,
    margin: "0 18px",
    justifyContent: "end",
  },
  titleAtack: {
    animation: `${
      theme.palette.mode === "dark" ? "myEffectDark" : "myEffectLight"
    }
     1.5s linear 0s infinite normal none`,
    "@keyframes myEffectDark": {
      "0%, 100%": { color: "#FA541C", fontWeight: "600" },
      "50%": { color: "#ffffff", fontWeight: "600" },
    },
    "@keyframes myEffectLight": {
      "0%, 100%": { color: "#FA541C", fontWeight: "600" },
      "50%": { color: "#000000", fontWeight: "600" },
    },
  },
  filterGroupNoAlert: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "23%",
    justifyContent: "end",
  },
  filterGroupNoAlertMobile: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0 18px",
    justifyContent: "end",
  },
});

export default styles;
