import {
  Avatar,
  Chip,
  Divider,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import useStyles from "./styles";
import dayjs from "dayjs";

export default function SubDetails(params) {
  const styles = useStyles();
  const {
    openDetalhes = false,
    setOpenDetalhes = () => {},
    selectedRow = {},
  } = params;

  return (
    <Modal
      open={openDetalhes}
      onClose={() => {
        setOpenDetalhes(false);
      }}
      sx={styles.modal}
    >
      <Paper sx={styles.paper}>
        {/* informações do usuario */}
        <Box sx={styles.header}>
          <Avatar src={selectedRow?.user?.foto || ""} sx={styles.avatar} />
          <Box sx={styles.headerContent}>
            <Typography variant="title">
              {selectedRow?.user?.nome} {selectedRow?.user?.sobrenome}
            </Typography>
            <Box sx={styles.headerLine}>
              <Box sx={styles.headerItem}>
                <Typography variant="caption" sx={styles.headerSubtitle}>
                  Navegador
                </Typography>
                <Typography sx={styles.headerText}>
                  {selectedRow?.session?.navegador}
                </Typography>
              </Box>
              <Box sx={styles.headerItem}>
                <Typography variant="caption" sx={styles.headerSubtitle}>
                  Sistema
                </Typography>
                <Typography sx={styles.headerText}>
                  {selectedRow?.session?.sistemaOperacional}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.headerLine}>
              <Box sx={styles.headerItem}>
                <Typography variant="caption" sx={styles.headerSubtitle}>
                  IP
                </Typography>
                <Typography sx={styles.headerText}>
                  {selectedRow?.session?.enderecoIp}
                </Typography>
              </Box>
              {selectedRow?.cliente ? (
                <Box sx={styles.headerItem}>
                  <Typography variant="caption" sx={styles.headerSubtitle}>
                    Cliente
                  </Typography>
                  <Typography sx={styles.headerText}>
                    {selectedRow?.cliente}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Box sx={styles.headerItem}>
              <Typography variant="caption" sx={styles.headerSubtitle}>
                Hora
              </Typography>
              <Typography sx={styles.headerText}>
                {selectedRow?.time}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />

        {/* conteúdo da mudança */}
        <Box sx={styles.content}>
          <Box sx={styles.contentHeader}>
            {
              <Typography>
                {selectedRow?.from && selectedRow?.action === "Edição"
                  ? "De: "
                  : ""}
              </Typography>
            }
            <Chip
              sx={{
                bgcolor:
                  selectedRow?.action === "Criação"
                    ? "success.main"
                    : selectedRow?.action === "Edição"
                    ? "secondary.main"
                    : "chip.pending",
              }}
              label={selectedRow?.action}
            />
          </Box>
          <Box>
            {/* Comunidades, Planos, Prefixos e Solicitações */}
            {selectedRow?.from && // Se não for vazio ou nulo
              (selectedRow?.table === "Comunidades" ||
                selectedRow?.table === "Planos" ||
                selectedRow?.table === "Prefixos" ||
                selectedRow?.table === "Solicitações") && (
                <Box sx={styles.comunidades.container}>
                  {Array.isArray(selectedRow?.to) ? ( // Se for array
                    selectedRow?.from?.map((item, index) => (
                      <Box key={item.id} sx={styles.comunidades.box}>
                        <TextField
                          variant="filled"
                          label={`${
                            selectedRow?.table === "Comunidades"
                              ? "Comunidade"
                              : selectedRow?.table === "Planos"
                              ? "Plano"
                              : selectedRow?.table === "Prefixos"
                              ? "Prefixo"
                              : "Solicitação"
                          }`}
                          value={
                            selectedRow?.table === "Solicitações" &&
                            item?.status === 3
                              ? "Arquivados"
                              : selectedRow?.table === "Prefixos" ||
                                selectedRow?.table === "Solicitações"
                              ? item?.prefixo
                              : item?.nome
                          }
                          size="small"
                          fullWidth
                          disabled={true}
                        />
                      </Box>
                    ))
                  ) : (
                    // Se for unico objeto
                    <Box
                      key={selectedRow?.from?.id}
                      sx={styles.comunidades.box}
                    >
                      <TextField
                        variant="filled"
                        label={`${
                          selectedRow?.table === "Comunidades"
                            ? "Comunidade"
                            : selectedRow?.table === "Planos"
                            ? "Plano"
                            : selectedRow?.table === "Prefixos"
                            ? "Prefixo"
                            : "Solicitação"
                        }`}
                        value={selectedRow?.from?.prefixo}
                        size="small"
                        fullWidth
                        disabled={true}
                      />
                    </Box>
                  )}
                </Box>
              )}

            {/* Vlan e Túnel */}
            {selectedRow?.from && // Se não for vazio ou nulo
              (selectedRow?.table === "Vlan" ||
                selectedRow?.table === "Túnel") && (
                <Box sx={styles.sessoes.container}>
                  <Box sx={styles.sessoes.row}>
                    <Box sx={styles.sessoes.item}>
                      <Typography
                        variant="caption"
                        sx={styles.sessoes.subtitle}
                      >
                        {selectedRow?.table === "Vlan"
                          ? "Título da Vlan"
                          : "Título do Túnel"}
                      </Typography>
                      <Typography sx={styles.sessoes.text}>
                        {selectedRow?.table === "Vlan"
                          ? selectedRow?.from?.titulo
                          : selectedRow?.from?.nome}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={styles.sessoes.row}>
                      <Box sx={styles.sessoes.item}>
                        <Typography
                          variant="caption"
                          sx={styles.sessoes.subtitle}
                        >
                          {selectedRow?.table === "Vlan"
                            ? "IP Local"
                            : "Tunnel Local"}
                        </Typography>
                        <Typography sx={styles.sessoes.text}>
                          {selectedRow?.table === "Vlan"
                            ? selectedRow?.from?.ipLocal
                            : selectedRow?.from?.ipTunnelLocal}
                        </Typography>
                      </Box>
                      <Box sx={styles.sessoes.item}>
                        <Typography
                          variant="caption"
                          sx={styles.sessoes.subtitle}
                        >
                          {selectedRow?.table === "Vlan"
                            ? "IP Cliente"
                            : "Tunnel Cliente"}
                        </Typography>
                        <Typography sx={styles.sessoes.text}>
                          {selectedRow?.table === "Vlan"
                            ? selectedRow?.from?.ipCliente
                            : selectedRow?.from?.ipTunnelCliente}
                        </Typography>
                      </Box>
                    </Box>
                    {selectedRow?.table === "Túnel" && (
                      <Box sx={styles.sessoes.row}>
                        <Box sx={styles.sessoes.item}>
                          <Typography
                            variant="caption"
                            sx={styles.sessoes.subtitle}
                          >
                            Source
                          </Typography>
                          <Typography sx={styles.sessoes.text}>
                            {selectedRow?.from?.ipSource}
                          </Typography>
                        </Box>

                        <Box sx={styles.sessoes.item}>
                          <Typography
                            variant="caption"
                            sx={styles.sessoes.subtitle}
                          >
                            Destination
                          </Typography>
                          <Typography sx={styles.sessoes.text}>
                            {selectedRow?.from?.ipDestiny}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    <Box sx={styles.sessoes.row}>
                      {selectedRow?.table === "Vlan" && (
                        <Box sx={styles.sessoes.item}>
                          <Typography
                            variant="caption"
                            sx={styles.sessoes.subtitle}
                          >
                            ASN Vlan
                          </Typography>
                          <Typography sx={styles.sessoes.text}>
                            {selectedRow?.from?.asnVlan}
                          </Typography>
                        </Box>
                      )}
                      <Box sx={styles.sessoes.item}>
                        <Typography
                          variant="caption"
                          sx={styles.sessoes.subtitle}
                        >
                          {selectedRow?.table === "Vlan"
                            ? "Vlan ID"
                            : "Tunnel ID"}
                        </Typography>
                        <Typography sx={styles.sessoes.text}>
                          {selectedRow?.table === "Vlan"
                            ? selectedRow?.from?.vlanId
                            : selectedRow?.from?.idTunnelCliente}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}

            {/* Outros */}
            {selectedRow?.from && // Se não for vazio ou nulo
              selectedRow?.table === "Outros" && (
                <Box sx={styles.outros.container}>
                  <Box sx={styles.outros.row}>
                    <TextField
                      variant="filled"
                      label={"Rota"}
                      size="small"
                      value={selectedRow?.from?.rota}
                      fullWidth
                      disabled={true}
                    />
                    <TextField
                      variant="filled"
                      label={"Plano"}
                      size="small"
                      value={selectedRow?.from?.plano?.nome}
                      fullWidth
                      disabled={true}
                    />
                  </Box>
                  <TextField
                    variant="filled"
                    label={"Comunidades"}
                    size="small"
                    value={selectedRow?.from?.comunidades
                      ?.map((com) => " " + com.nome)
                      .toString()
                      .trim()}
                    fullWidth
                    disabled={true}
                  />
                </Box>
              )}

            {/* Perfil */}
            {selectedRow?.from && selectedRow?.table === "Perfil" && (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="CNPJ"
                    size="small"
                    value={selectedRow?.from?.cnpj}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    variant="filled"
                    label="Razão Social"
                    size="small"
                    value={selectedRow?.from?.razaoSocial}
                    fullWidth
                    disabled={true}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="Nome Fantasia"
                    size="small"
                    value={selectedRow?.from?.nomeFantasia}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    variant="filled"
                    label="ASN"
                    size="small"
                    value={selectedRow?.from?.as}
                    fullWidth
                    disabled={true}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="CEP"
                    size="small"
                    value={selectedRow?.from?.ClientesCadastro?.cep}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    variant="filled"
                    label="Estado"
                    size="small"
                    value={selectedRow?.from?.ClientesCadastro?.estado}
                    fullWidth
                    disabled={true}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="Cidade"
                    size="small"
                    value={selectedRow?.from?.ClientesCadastro?.cidade}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    variant="filled"
                    label="Bairro"
                    size="small"
                    value={selectedRow?.from?.ClientesCadastro?.bairro}
                    fullWidth
                    disabled={true}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="Logradouro"
                    size="small"
                    value={selectedRow?.from?.ClientesCadastro?.logradouro}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    variant="filled"
                    label="Número"
                    size="small"
                    value={selectedRow?.from?.numero}
                    fullWidth
                    disabled={true}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="Complemento"
                    size="small"
                    value={selectedRow?.from?.ClientesCadastro?.complemento}
                    sx={{ width: "49%" }}
                    disabled={true}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="Telefone"
                    size="small"
                    value={selectedRow?.from?.ClientesCadastro?.telefone}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    variant="filled"
                    label="Email"
                    size="small"
                    value={selectedRow?.from?.ClientesCadastro?.email}
                    fullWidth
                    disabled={true}
                  />
                </Box>
              </Box>
            )}
          </Box>

          {selectedRow?.action === "Edição" && selectedRow?.to && (
            <Typography>Para:</Typography>
          )}
          <Box>
            {/* Comunidades, Planos, Prefixos e Solicitações */}
            {selectedRow?.to && // Se não for vazio ou nulo
              (selectedRow?.table === "Comunidades" ||
                selectedRow?.table === "Planos" ||
                selectedRow?.table === "Prefixos" ||
                selectedRow?.table === "Solicitações") && (
                <Box sx={styles.comunidades.container}>
                  {Array.isArray(selectedRow?.to) ? ( // Se for array
                    selectedRow?.to?.map((item, index) => (
                      <Box key={item.id} sx={styles.comunidades.box}>
                        <TextField
                          variant="filled"
                          label={`${
                            selectedRow?.table === "Comunidades"
                              ? "Comunidade"
                              : selectedRow?.table === "Planos"
                              ? "Plano"
                              : selectedRow?.table === "Prefixos"
                              ? "Prefixo"
                              : "Solicitação"
                          }`}
                          value={
                            selectedRow?.table === "Solicitações" &&
                            item?.status === 3
                              ? "Arquivados"
                              : selectedRow?.table === "Prefixos" ||
                                selectedRow?.table === "Solicitações"
                              ? item?.prefixo
                              : item?.nome
                          }
                          size="small"
                          fullWidth
                          disabled={true}
                        />
                      </Box>
                    ))
                  ) : (
                    // Se for unico objeto
                    <Box
                      key={selectedRow?.from?.id}
                      sx={styles.comunidades.box}
                    >
                      <TextField
                        variant="filled"
                        label={`${
                          selectedRow?.table === "Comunidades"
                            ? "Comunidade"
                            : selectedRow?.table === "Planos"
                            ? "Plano"
                            : selectedRow?.table === "Prefixos"
                            ? "Prefixo"
                            : "Solicitação"
                        }`}
                        value={
                          selectedRow?.table === "Solicitações"
                            ? "Arquivados"
                            : selectedRow?.to?.prefixo
                        }
                        size="small"
                        fullWidth
                        disabled={true}
                      />
                    </Box>
                  )}
                </Box>
              )}

            {/* Vlan e Túnel */}
            {selectedRow?.to && // Se não for vazio ou nulo
              (selectedRow?.table === "Vlan" ||
                selectedRow?.table === "Túnel") && (
                <Box sx={styles.sessoes.container}>
                  <Box sx={styles.sessoes.row}>
                    <Box sx={styles.sessoes.item}>
                      <Typography
                        variant="caption"
                        sx={styles.sessoes.subtitle}
                      >
                        {selectedRow?.table === "Vlan"
                          ? "Título da Vlan"
                          : "Título do Túnel"}
                      </Typography>
                      <Typography sx={styles.sessoes.text}>
                        {selectedRow?.table === "Vlan"
                          ? selectedRow?.to?.titulo
                          : selectedRow?.to?.nome}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box sx={styles.sessoes.row}>
                      <Box sx={styles.sessoes.item}>
                        <Typography
                          variant="caption"
                          sx={styles.sessoes.subtitle}
                        >
                          {selectedRow?.table === "Vlan"
                            ? "IP Local"
                            : "Tunnel Local"}
                        </Typography>
                        <Typography sx={styles.sessoes.text}>
                          {selectedRow?.table === "Vlan"
                            ? selectedRow?.to?.ipLocal
                            : selectedRow?.to?.ipTunnelLocal}
                        </Typography>
                      </Box>
                      <Box sx={styles.sessoes.item}>
                        <Typography
                          variant="caption"
                          sx={styles.sessoes.subtitle}
                        >
                          {selectedRow?.table === "Vlan"
                            ? "IP Cliente"
                            : "Tunnel Cliente"}
                        </Typography>
                        <Typography sx={styles.sessoes.text}>
                          {selectedRow?.table === "Vlan"
                            ? selectedRow?.to?.ipCliente
                            : selectedRow?.to?.ipTunnelCliente}
                        </Typography>
                      </Box>
                    </Box>
                    {selectedRow?.table === "Túnel" && (
                      <Box sx={styles.sessoes.row}>
                        <Box sx={styles.sessoes.item}>
                          <Typography
                            variant="caption"
                            sx={styles.sessoes.subtitle}
                          >
                            Source
                          </Typography>
                          <Typography sx={styles.sessoes.text}>
                            {selectedRow?.to?.ipSource}
                          </Typography>
                        </Box>

                        <Box sx={styles.sessoes.item}>
                          <Typography
                            variant="caption"
                            sx={styles.sessoes.subtitle}
                          >
                            Destination
                          </Typography>
                          <Typography sx={styles.sessoes.text}>
                            {selectedRow?.to?.ipDestiny}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    <Box sx={styles.sessoes.row}>
                      {selectedRow?.table === "Vlan" && (
                        <Box sx={styles.sessoes.item}>
                          <Typography
                            variant="caption"
                            sx={styles.sessoes.subtitle}
                          >
                            ASN Vlan
                          </Typography>
                          <Typography sx={styles.sessoes.text}>
                            {selectedRow?.to?.asnVlan}
                          </Typography>
                        </Box>
                      )}
                      <Box sx={styles.sessoes.item}>
                        <Typography
                          variant="caption"
                          sx={styles.sessoes.subtitle}
                        >
                          {selectedRow?.table === "Vlan"
                            ? "Vlan ID"
                            : "Tunnel ID"}
                        </Typography>
                        <Typography sx={styles.sessoes.text}>
                          {selectedRow?.table === "Vlan"
                            ? selectedRow?.to?.vlanId
                            : selectedRow?.to?.idTunnelCliente}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}

            {/* Outros */}
            {selectedRow?.to && // Se não for vazio ou nulo
              selectedRow?.table === "Outros" && (
                <Box sx={styles.outros.container}>
                  <Box sx={styles.outros.row}>
                    <TextField
                      variant="filled"
                      label={"Rota"}
                      size="small"
                      value={selectedRow?.to?.rota}
                      fullWidth
                      disabled={true}
                    />
                    <TextField
                      variant="filled"
                      label={"Plano"}
                      size="small"
                      value={selectedRow?.to?.plano?.nome}
                      fullWidth
                      disabled={true}
                    />
                  </Box>
                  <TextField
                    variant="filled"
                    label={"Comunidades"}
                    size="small"
                    value={selectedRow?.to?.comunidades
                      ?.map((com) => " " + com.nome)
                      .toString()
                      .trim()}
                    fullWidth
                    disabled={true}
                  />
                </Box>
              )}

            {/* Perfil */}
            {selectedRow?.to && selectedRow?.table === "Perfil" && (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="CNPJ"
                    size="small"
                    value={selectedRow?.to?.cnpj}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    variant="filled"
                    label="Razão Social"
                    size="small"
                    value={selectedRow?.to?.razaoSocial}
                    fullWidth
                    disabled={true}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="Nome Fantasia"
                    size="small"
                    value={selectedRow?.to?.nomeFantasia}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    variant="filled"
                    label="ASN"
                    size="small"
                    value={selectedRow?.to?.as}
                    fullWidth
                    disabled={true}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="CEP"
                    size="small"
                    value={selectedRow?.to?.ClientesCadastro?.cep}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    variant="filled"
                    label="Estado"
                    size="small"
                    value={selectedRow?.to?.ClientesCadastro?.estado}
                    fullWidth
                    disabled={true}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="Cidade"
                    size="small"
                    value={selectedRow?.to?.ClientesCadastro?.cidade}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    variant="filled"
                    label="Bairro"
                    size="small"
                    value={selectedRow?.to?.ClientesCadastro?.bairro}
                    fullWidth
                    disabled={true}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="Logradouro"
                    size="small"
                    value={selectedRow?.to?.ClientesCadastro?.logradouro}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    variant="filled"
                    label="Número"
                    size="small"
                    value={selectedRow?.to?.numero}
                    fullWidth
                    disabled={true}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="Complemento"
                    size="small"
                    value={selectedRow?.to?.ClientesCadastro?.complemento}
                    sx={{ width: "49%" }}
                    disabled={true}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <TextField
                    variant="filled"
                    label="Telefone"
                    size="small"
                    value={selectedRow?.to?.ClientesCadastro?.telefone}
                    fullWidth
                    disabled={true}
                  />
                  <TextField
                    variant="filled"
                    label="Email"
                    size="small"
                    value={selectedRow?.to?.ClientesCadastro?.email}
                    fullWidth
                    disabled={true}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}
