import {
  IconButton,
  Modal,
  Paper,
  Box,
  Badge,
  Avatar,
  Typography,
  Button,
  TextField,
  Divider,
  InputAdornment,
} from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import useStyles from "./styles";
import api from "../../../../../services/api";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import InternoModalDeleteCliente from "../../../../../components/internoModalDeleteCliente";
export default function ModalEditSenha({
  openModalEditSenha,
  setOpenModalEditSenha = () => {},
  selectedRow,
  userEdit,
  setSnackbar = () => {},
}) {
  const [changeSenha, setChangSenha] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [senhaValid, setSenhaValid] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [confirmSenha, setConfirmSenha] = useState("");
  const [Null, setNull] = useState(false);
  const [senha, setSenha] = useState("");
  const [disableSaveButtom, setDisableSaveButtom] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);
  const deleteBody = `Você está alterando a senha do usuário "${
    selectedRow?.login || ""
  }". Deseja confirmar a alteração da senha?`;
  const confirmDelete = "CONFIRMAR";

  useEffect(() => {
    if (senha.length >= 6 && /[a-zA-Z]/.test(senha) && /\d/.test(senha))
      setSenhaValid(true);
    else setSenhaValid(false);
  }, [senha]);

  const handleUpdate = () => {
    if (!senha || !confirmSenha || confirmSenha !== senha || !senhaValid) {
      setNull(true);
      return;
    }
    setDisableSaveButtom(true);
    setOpenDeleteModal(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };
  const handleResetSenha = async () => {
    try {
      const response = await api.post("/interno/resetSenhaUserCliente", {
        usuarioId: selectedRow?.id,
        password: senha,
      });
      setChangSenha(false);

      setSnackbar({
        children: "Senha alterada com sucesso",
        severity: "success",
      });
      handleclose();
    } catch (error) {
      console.error("Erro ao trocar senha");
      setSnackbar({
        children: "Erro ao alterar senha",
        severity: "error",
      });
    } finally {
      setOpenDeleteModal(false);
      setDisableSaveButtom(false);
    }
  };
  const handleCancelChangeSenha = () => {
    setChangSenha(false);
    setSenhaValid(false);
    setNull(false);
  };

  const handleclose = () => {
    setSenha("");
    setConfirmSenha("");
    setOpenModalEditSenha(false);
    setChangSenha(false);
    setSenhaValid(false);
    setNull(false);
  };

  return (
    <Modal
      sx={styles.containerModal}
      open={openModalEditSenha}
      onClose={handleclose}
    >
      <Paper
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        sx={styles.container}
      >
        <Stack sx={styles.StackFoto}>
          <Box sx={styles.BoxAvatar}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Avatar
                src={userEdit?.foto}
                color="secondary"
                sx={styles.Avatar}
              />
            </Badge>
          </Box>
        </Stack>
        <Box sx={styles.boxAreaModalPerfil}>
          <Box sx={styles.boxAreaTituloModalPerfil}>
            <Typography sx={{ color: "#FFFFFF" }} variant="title">
              Perfil
            </Typography>
          </Box>
          <Box sx={styles.boxAreaConteudoModalPerfil}>
            <Box sx={styles.boxAreaButtomModalPerfil}>
              <Box sx={styles.containerButtonSalvar}>
                <>
                  <Button
                    onClick={handleclose}
                    sx={styles.buttonCancelar}
                    variant="text"
                    disabled={disableSaveButtom}
                  >
                    {" "}
                    Cancelar
                  </Button>
                  <Button
                    disabled={disableSaveButtom}
                    onClick={() => handleUpdate()}
                    sx={styles.buttonSave}
                    variant="text"
                  >
                    Alterar senha
                  </Button>
                </>
              </Box>
            </Box>

            <>
              <Stack
                direction={"column"}
                padding={"10px"}
                gap={"25px"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                width={"100%"}
              >
                <Stack direction={"column"} width={"100%"}>
                  <Typography variant="title">Alteração de senha</Typography>
                  <Stack direction={"column"} width={"100%"}>
                    <Box>
                      <Typography sx={styles.textBold} variant="mySubtitle1">
                        Login:{" "}
                      </Typography>
                      <Typography variant="mySubtitle1">
                        {selectedRow?.login}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack direction={"column"} width={"100%"}>
                    <Box>
                      <Typography sx={styles.textBold} variant="mySubtitle1">
                        E-mail:{" "}
                      </Typography>
                      <Typography variant="mySubtitle1">
                        {selectedRow?.email}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
                <Divider orientation="horizontal" sx={styles.divider} />
                <Stack direction={"column"} spacing={"10px"} width={"100%"}>
                  <Typography sx={styles.title}>
                    Digite a nova senha e confirme em seguida.
                  </Typography>
                  <Stack spacing={"10px"}>
                    <TextField
                      variant="filled"
                      label="Senha"
                      disabled={disableSaveButtom}
                      type={showPassword ? "text" : "password"}
                      error={(!senha || !senhaValid) && Null}
                      onChange={(event) => {
                        setSenha(
                          event?.target?.value?.trim()?.replace(" ", "")
                        );
                      }}
                      value={senha}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword((show) => !show)}
                              onMouseDown={(event) => {
                                event.preventDefault();
                              }}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        (!senha || !senhaValid) && Null
                          ? "A senha precisa ter no mínimo 6 digítos, uma letra e um número."
                          : ""
                      }
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event?.preventDefault();
                          document?.getElementById("confirmarSenha").focus();
                        }
                      }}
                    />
                    <TextField
                      sx={styles.TextField}
                      variant="filled"
                      id="confirmarSenha"
                      label="Confirmar Senha"
                      disabled={disableSaveButtom}
                      error={
                        (!senha && Null) || (Null && senha !== confirmSenha)
                      }
                      type={showPassword ? "text" : "password"}
                      onKeyDown={handleKeyDown}
                      onChange={(event) => {
                        setConfirmSenha(
                          event?.target?.value?.trim()?.replace(" ", "")
                        );
                      }}
                      value={confirmSenha}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword((show) => !show)}
                              onMouseDown={(event) => {
                                event.preventDefault();
                              }}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      helperText={
                        (!senha || !senhaValid) && Null
                          ? "A senha precisa ter no mínimo 6 digítos, uma letra e um número."
                          : ""
                      }
                    />
                  </Stack>
                </Stack>
              </Stack>
            </>
          </Box>
        </Box>
        <InternoModalDeleteCliente
          openDeleteModal={openDeleteModal}
          deleteLoad={false}
          setOpenDeleteModal={setOpenDeleteModal}
          execute={handleResetSenha}
          severity={"warning"}
          contentText={deleteBody}
          confirmText={confirmDelete}
        />
      </Paper>
    </Modal>
  );
}
