import { bgcolor, height, width } from "@mui/system";

const styles = (theme) => ({
  container: {
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    gap: 3,
  },
  containerTitulo: {
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  containerTabs: {
    width: "83%",
  },

  containerTab: {
    width: "100%",
    display: "flex",
  },
});

export default styles;
