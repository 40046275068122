import { useTheme } from "@emotion/react";
import "dayjs/locale/pt-br";
import { Box, Tab, Tabs, Snackbar, Alert } from "@mui/material";
import useStyle from "./styles";
import { useState } from "react";
import Relatorio from "./relatorio";
import Configuracoes from "./configuracoes";
import TrafegoTotal from "./trafegoTotal";

export default function Relatorios(params) {
  const [snackbar, setSnackbar] = useState(null);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const styles = useStyle(theme);

  return (
    <Box sx={styles.container}>
      <Tabs
        scrollButtons={true}
        value={value}
        sx={styles.tabs}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <Tab
          label={"Relatórios"}
          value={0}
          sx={styles.tab}
          disabled={loading}
        />
        <Tab
          label={"Configurações"}
          value={1}
          sx={styles.tab}
          disabled={loading}
        />
        <Tab label={"Tráfego"} value={2} sx={styles.tab} disabled={loading} />
      </Tabs>

      {value === 0 && <Relatorio loading={loading} setLoading={setLoading} />}
      {value === 1 && <Configuracoes />}
      {value === 2 && <TrafegoTotal setValue={setValue} />}

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
