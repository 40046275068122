const styles = (props) => ({
  container: {
    maxHeight: "100vh",
  },

  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "center",
    gap: "20px",
    zIndex: 1,
    position: "relative",
    top: "-700px",
  },

  buttonNavigate: {
    position: "absolute",
    top: "25px",
    right: "25px",
  },
  boxImg: {
    backgroundRepeat: "no-repeat",
    borderRadius: "0px!important",

    justifyContent: "center",
    alignContent: "center",
    backgroundPosition: "0% 75%",
  },
  textfield: {
    width: "80%",
    maxWidth: "500px",
    minWidth: "220px",
    backgroundColor: "#ffffff",
    opacity: "0.8",
    borderRadius: "4px",
    "& input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 30px #ffffff inset",
      WebkitTextFillColor: "black !important",
    },
    input: {
      color: "#000000",
    },
    "& .MuiInputLabel-root": {
      color: "#000000",
    },
  },
  button: {
    width: "30%",
    maxWidth: "232px",
    minWidth: "150px",
    backgroundColor: "#ffffff",
    color: "#000000",
    mt: "15px",
  },
  boxRecover: {
    mr: {
      xs: "0px",
      sm: "350px",
    },
  },
  img: { width: "auto", maxHeight: "80px", maxWidth: "80%" },
  boxForm: { display: "flex", flexDirection: "column", gap: 1, width: "700px" },
  buttonRecover: { color: "#ffffff" },
});

export default styles;
