import {
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Box,
  Badge,
  Avatar,
  Typography,
  Button,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import Resizer from "react-image-file-resizer";
import useStyles from "./styles";
import apiCliente from "../../../../services/apiCliente";
import { AddAPhoto, Person, PersonOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
export default function ModalEditUserCliente({
  openModalEditUser,
  setOpenModalEditUser = () => {},
  selectedRow,
  setUserEdit = () => {},
  userEdit,
  idCliente,
  setSnackbar = () => {},
  setUserCliente = () => {},
  userCliente,
  permissoes,
}) {
  const [Null, setNull] = useState(false);
  const [codigoPermissao, setCodigoPermissao] = useState("");
  const [disableSaveButtom, setDisableSaveButtom] = useState(false);
  const [emailValido, setEmailValido] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    setUserEdit({
      id: selectedRow?.id,
      nome: selectedRow?.nome,
      sobrenome: selectedRow?.sobrenome,
      login: selectedRow?.login,
      email: selectedRow?.email,
      status: selectedRow?.status,
      foto: selectedRow?.foto,
    });
    let permissao =
      Array.isArray(permissoes) &&
      permissoes?.filter(
        (permissao) => permissao?.id === selectedRow?.permissoes?.permissaoId
      );
    setCodigoPermissao(
      (Array.isArray(permissao) && permissao[0]?.codigoPermissao) || ""
    );
  }, [selectedRow]);

  useEffect(() => {
    if (emailRegex.test(userEdit?.email)) {
      setEmailValido(true);
    } else {
      setEmailValido(false);
    }
  }, [userEdit?.email]);

  const handleUpdateCliente = () => {
    if (!userEdit?.login || !emailValido) {
      setSnackbar({
        children: "Necessário informar login e e-mail",
        severity: "error",
      });
      setNull(true);
    } else {
      updateUserCliente();
    }
  };

  const updateUserCliente = async () => {
    setDisableSaveButtom(true);
    try {
      let permissaoSelect = permissoes.filter(
        (permissao) => permissao.codigoPermissao === codigoPermissao
      );

      const response = await apiCliente.post("/updateUserCliente", {
        usuarioId: userEdit?.id,
        nome: userEdit?.nome,
        sobrenome: userEdit?.sobrenome,
        login: userEdit?.login,
        email: userEdit?.email,
        status: userEdit?.status,
        foto: userEdit?.foto,
        clienteId: idCliente,
        permissao: (Array.isArray(permissaoSelect) && {
          permissaoId: permissaoSelect[0]?.id || "",
          usuarioId: userEdit?.id,
        }) || {
          permissaoId: selectedRow?.permissoes?.permissaoId,
          usuarioId: userEdit?.id,
        },
      });
      setSnackbar({
        children: "Perfil atualizado com sucesso",
        severity: "success",
      });

      const userFilter = userCliente?.filter(
        (user) => user?.id === userEdit?.id
      );
      let objetoUserClient = userFilter[0];

      objetoUserClient = {
        ...objetoUserClient,
        login: userEdit?.login,
        email: userEdit?.email,
        nome: userEdit?.nome,
        sobrenome: userEdit?.sobrenome,
        status: userEdit?.status,
        foto: userEdit?.foto,
        permissoes: (Array.isArray(permissaoSelect) && {
          permissaoId: permissaoSelect[0]?.id,
          usuarioId: userEdit?.id,
        }) || {
          permissaoId: selectedRow?.permissoes?.permissaoId,
          usuarioId: userEdit?.id,
        },
      };
      const newDataUserClient = userCliente?.filter(
        (user) => user?.id !== userEdit?.id
      );
      newDataUserClient.push(objetoUserClient);
      setUserCliente(newDataUserClient);
      handleClose();
    } catch (error) {
      let dataError = error?.response?.data;

      if (dataError) {
        if (dataError === "Email já existente") {
          setSnackbar({
            children: "E-mail existente",
            severity: "error",
          });
        } else if (dataError === "Login já existente") {
          setSnackbar({
            children: "Login existente",
            severity: "error",
          });
        } else {
          setSnackbar({
            children: "Erro ao atualizar informações do perfil",
            severity: "error",
          });
        }
      } else {
        setSnackbar({
          children: "Erro ao atualizar informações do perfil",
          severity: "error",
        });
      }
    } finally {
      setDisableSaveButtom(false);
    }
  };

  const handleClose = () => {
    setOpenModalEditUser(false);
    setNull(false);

    setUserEdit({
      id: selectedRow?.id,
      nome: selectedRow?.nome,
      sobrenome: selectedRow?.sobrenome,
      login: selectedRow?.login,
      email: selectedRow?.email,
      status: selectedRow?.status,
      foto: selectedRow?.foto,
    });
    let permissao =
      Array.isArray(permissoes) &&
      permissoes?.filter(
        (permissao) => permissao?.id === selectedRow?.permissoes?.permissaoId
      );
    setCodigoPermissao(
      (Array.isArray(permissao) && permissao[0]?.codigoPermissao) || ""
    );
  };

  return (
    <Modal
      sx={styles.containerModal}
      open={openModalEditUser}
      onClose={handleClose}
    >
      <Paper
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        sx={styles.container}
      >
        <Stack sx={styles.StackFoto}>
          <Box sx={styles.BoxAvatar}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Tooltip title={"adicionar imagem"}>
                  <IconButton
                    variant="outlined"
                    component="label"
                    color="primary"
                    sx={styles.containerIconButton}
                    disabled={disableSaveButtom}
                  >
                    <AddAPhoto />
                    <input
                      hidden
                      accept=".png, .jpg, .jpeg"
                      type="file"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file?.size < 1024 * 1000) {
                          Resizer.imageFileResizer(
                            file,
                            500,
                            500,
                            "JPEG",
                            80,
                            0,
                            (uri) => {
                              setUserEdit((prev) => ({ ...prev, foto: uri }));
                            },
                            "base64",
                            500,
                            500
                          );
                          const fileReader = new FileReader();
                          fileReader.readAsDataURL(file);
                          fileReader.onload = () => {};
                          fileReader.onerror = (error) => {
                            console.error("Error:", error);
                          };
                        } else {
                          setSnackbar({
                            children: "Tamanho máximo 1MB",
                            severity: "error",
                          });
                        }
                      }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              }
            >
              <Avatar
                src={userEdit?.foto}
                color="secondary"
                sx={styles.Avatar}
              />
            </Badge>
          </Box>
        </Stack>
        <Box sx={styles.boxAreaModalPerfil}>
          <Box sx={styles.boxAreaTituloModalPerfil}>
            <Typography color={"#FFFFFF"} variant="title">
              Perfil
            </Typography>
          </Box>
          <Box sx={styles.boxAreaConteudoModalPerfil}>
            <Box sx={styles.boxAreaButtomModalPerfil}>
              <Box sx={styles.containerButtonSalvar}>
                <>
                  <Button
                    onClick={handleClose}
                    sx={styles.buttonCancelar}
                    variant="text"
                    disabled={disableSaveButtom}
                  >
                    {" "}
                    Cancelar
                  </Button>
                  <Button
                    disabled={disableSaveButtom}
                    onClick={handleUpdateCliente}
                    sx={styles.buttonSave}
                    variant="text"
                  >
                    Salvar
                  </Button>
                </>
              </Box>
            </Box>

            <>
              <Stack spacing={1} direction="row">
                <TextField
                  variant="filled"
                  label="Nome"
                  size="small"
                  value={userEdit?.nome}
                  disabled={disableSaveButtom}
                  sx={styles.TextField}
                  onChange={(e) =>
                    setUserEdit((prev) => ({ ...prev, nome: e.target.value }))
                  }
                />

                <TextField
                  variant="filled"
                  label="Sobrenome"
                  size="small"
                  value={userEdit?.sobrenome}
                  disabled={disableSaveButtom}
                  sx={styles.TextField}
                  onChange={(e) =>
                    setUserEdit((prev) => ({
                      ...prev,
                      sobrenome: e.target.value,
                    }))
                  }
                />
              </Stack>
              <Divider orientation="horizontal" sx={styles.divider} />
              <Stack spacing={1} direction="row">
                <TextField
                  variant="filled"
                  label="Login"
                  size="small"
                  value={userEdit?.login}
                  disabled={disableSaveButtom}
                  sx={styles.TextField}
                  onChange={(e) =>
                    setUserEdit((prev) => ({
                      ...prev,
                      login: e.target.value,
                    }))
                  }
                  helperText={
                    !userEdit?.login && Null
                      ? "Necessário informar um login"
                      : ""
                  }
                  error={!userEdit?.login && Null}
                />

                <TextField
                  variant="filled"
                  label="E-mail"
                  size="small"
                  disabled={disableSaveButtom}
                  value={userEdit?.email}
                  sx={styles.TextField}
                  onChange={(e) =>
                    setUserEdit((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                  helperText={
                    (!userEdit?.email || !emailValido) && Null
                      ? "E-mail válido"
                      : ""
                  }
                  error={(!userEdit?.email || !emailValido) && Null}
                />
              </Stack>
              <Divider orientation="horizontal" sx={styles.divider} />

              <Stack spacing={1} direction="row" sx={{ width: "100%" }}>
                <FormControl
                  variant="filled"
                  size="small"
                  sx={styles.TextField}
                  fullWidth
                  disabled={disableSaveButtom}
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    value={!userEdit?.status ? "DESATIVADO" : "ATIVO"}
                    onChange={(e) => {
                      setUserEdit((prev) => ({
                        ...prev,
                        status: e.target.value === "DESATIVADO" ? 0 : 1,
                      }));
                    }}
                    name="status"
                    color="primary"
                  >
                    <MenuItem value={"ATIVO"}>
                      <Box sx={styles.boxStatusItem}>
                        <Person sx={styles.person} />
                        <Typography>ATIVO</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={"DESATIVADO"}>
                      <Box sx={styles.boxStatusItem}>
                        <PersonOff sx={styles.person} />
                        <Typography>DESATIVADO</Typography>
                      </Box>
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="filled"
                  size="small"
                  sx={styles.TextField}
                  fullWidth
                  disabled={disableSaveButtom}
                >
                  <InputLabel>Permissão</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    value={!codigoPermissao ? "TÉCNICO" : codigoPermissao}
                    label="Permissão"
                    onChange={(event) => {
                      setCodigoPermissao(event.target.value);
                    }}
                    name="status"
                    color="primary"
                  >
                    <MenuItem value={"TÉCNICO"}>TÉCNICO</MenuItem>
                    {Array.isArray(permissoes) &&
                      permissoes?.map((permissao, index) => (
                        <MenuItem
                          key={index}
                          value={permissao?.codigoPermissao}
                        >
                          {permissao?.codigoPermissao?.toUpperCase()}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Stack>
            </>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}
