import { useTheme } from "@emotion/react";
import "dayjs/locale/pt-br";
import {
  Box,
  Button,
  FilledInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Checkbox,
  Divider,
} from "@mui/material";
import api from "../../../../../services/api";
import { useOutletContext } from "react-router-dom";
import useStyle from "./styles";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";

export default function Configuracoes(params) {
  const [sb, clienteInfo, si] = useOutletContext();
  const [snackbar, setSnackbar] = useState(null);
  const [Null, setNull] = useState(false);
  const [valorExecedente, setValorExcedente] = useState();
  const [configPercentil, setConfigPercentil] = useState({
    percentil: "",
    permissao: "",
    valorExcedente: "",
    tarifacaoExcedente: "",
    tarifacaoFixa: "",
    clienteId: clienteInfo?.id,
    id: "",
  });
  const [formDataConfigPercentil, setFormDataConfigPercentil] = useState({
    percentil: "",
    permissao: "",
    valorExcedente: "",
    tarifacaoExcedente: "",
    tarifacaoFixa: "",
    clienteId: clienteInfo?.id,
    id: "",
  });
  const [loadingConfig, setLoadingConfig] = useState(false);
  const [editConfig, setEditConfig] = useState(true);

  const theme = useTheme();
  const styles = useStyle(theme);

  useEffect(() => {
    getConfigPercentil();
  }, [clienteInfo]);
  useEffect(() => {
    setValorExcedente(
      (
        (parseFloat(formDataConfigPercentil?.tarifacaoExcedente) *
          convertNumero(formDataConfigPercentil?.tarifacaoFixa)) /
          100 || 0
      )
        ?.toFixed(2)
        ?.replace(".", ",")
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    );
  }, [
    formDataConfigPercentil?.tarifacaoExcedente,
    formDataConfigPercentil?.tarifacaoFixa,
  ]);

  const handleValorConfig = (e) => {
    setFormDataConfigPercentil((prev) => ({
      ...prev,
      tarifacaoFixa: mascara(e?.target?.value),
    }));
  };

  const handleValorExcedenteConfig = (e) => {
    setFormDataConfigPercentil((prev) => ({
      ...prev,
      tarifacaoExcedente: e?.target?.value?.replace(/\D/g, ""),
    }));
  };

  const handleLimiteTráfegoConfig = (e) => {
    setFormDataConfigPercentil((prev) => ({
      ...prev,
      valorExcedente: e?.target?.value?.replace(/\D/g, ""),
    }));
  };

  const mascara = (value) => {
    const valorInteiro = value?.replace(/\D/g, "");
    const valorFormatado = (valorInteiro / 100)
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return valorFormatado;
  };

  const getConfigPercentil = async () => {
    try {
      const response = await api.post("/interno/percentilCliente", {
        clienteId: clienteInfo?.id,
      });
      let porcentagemExcedente = Math.round(
        (convertNumero(response?.data?.tarifacaoExcedente) * 100) /
          convertNumero(response?.data?.tarifacaoFixa).toFixed(2)
      );
      setConfigPercentil(response?.data);
      setFormDataConfigPercentil(response?.data);
      setFormDataConfigPercentil((prev) => ({
        ...prev,
        tarifacaoExcedente: porcentagemExcedente,
      }));
      setConfigPercentil((prev) => ({
        ...prev,
        tarifacaoExcedente: porcentagemExcedente,
      }));
    } catch (erro) {
      console.error(erro);
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
    }
  };

  const validacaoConfig = () => {
    if (
      !formDataConfigPercentil?.percentil ||
      !formDataConfigPercentil?.valorExcedente ||
      !formDataConfigPercentil?.tarifacaoExcedente ||
      !formDataConfigPercentil?.tarifacaoFixa
    ) {
      setNull(true);
    } else {
      updateConfigPercentil();
    }
  };
  const updateConfigPercentil = async () => {
    setLoadingConfig(true);

    try {
      const response = await api.post("/interno/updatePercentilCliente", {
        percentil: parseInt(formDataConfigPercentil?.percentil),
        permissao: formDataConfigPercentil?.permissao,
        valorExcedente: formDataConfigPercentil?.valorExcedente,
        tarifacaoExcedente: valorExecedente,
        tarifacaoFixa: formDataConfigPercentil?.tarifacaoFixa,
        clienteId: clienteInfo?.id,
        id: formDataConfigPercentil?.id,
      });

      setConfigPercentil(response?.data);
      setFormDataConfigPercentil(response?.data);
      let porcentagemExcedente = Math.round(
        (convertNumero(response?.data?.tarifacaoExcedente) * 100) /
          convertNumero(response?.data?.tarifacaoFixa).toFixed(2)
      );
      setFormDataConfigPercentil((prev) => ({
        ...prev,
        tarifacaoExcedente: porcentagemExcedente,
      }));
      setConfigPercentil((prev) => ({
        ...prev,
        tarifacaoExcedente: porcentagemExcedente,
      }));
      setEditConfig(true);
      setSnackbar({
        children: "Configurações atualizadas",
        severity: "success",
      });
    } catch (erro) {
      console.error(erro);
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoadingConfig(false);
    }
  };

  const handleEditConfig = () => {
    setEditConfig(false);
  };

  const handleCancelarConfig = () => {
    setEditConfig(true);
    setFormDataConfigPercentil(configPercentil);
  };
  const handleChangePercentil = (e) => {
    const regex = /^([0-9]|([1-9][0-9])|100)$/;
    let value = e?.target?.value;
    if (value === "" || regex.test(value)) {
      setFormDataConfigPercentil((prev) => ({
        ...prev,
        percentil: e?.target?.value?.replace(/\D/g, ""),
      }));
    }
  };

  const convertNumero = (value) => {
    return parseFloat(value?.replace(/\./g, "")?.replace(",", "."));
  };

  return (
    <Box sx={styles.container}>
      <Paper
        sx={styles.containerPaper}
        elevation={3}
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
      >
        <Typography variant="mySubtitle">Configurações de relatório</Typography>
        <Paper variant="outlined" sx={styles.paper}>
          <Box sx={styles.containerExcedente}>
            <Box sx={styles.stack}>
              <FormControl>
                <TextField
                  sx={styles.valorMbps}
                  variant="filled"
                  label="Percentil cliente"
                  value={formDataConfigPercentil?.percentil || ""}
                  disabled={editConfig}
                  onChange={handleChangePercentil}
                  helperText={
                    !formDataConfigPercentil?.percentil && Null
                      ? "Necessário informar percentil"
                      : ""
                  }
                  error={!formDataConfigPercentil?.percentil && Null}
                />
              </FormControl>
              {/* <FormControl>
                <TextField
                  variant="filled"
                  label="Dia de vencimento"
                  value={formDataConfigPercentil?.diaVencimento || ""}
                  onChange={handleDia}
                  disabled={editConfig}
                  autoComplete="off"
                  sx={styles.valorMbps}
                  helperText={
                    !formDataConfigPercentil?.diaVencimento && Null
                      ? "Necessário dia de vencimento"
                      : ""
                  }
                  error={!formDataConfigPercentil?.diaVencimento && Null}
                />
              </FormControl> */}
            </Box>
            <Divider sx={styles.divider}></Divider>
            <Box sx={styles.stack}>
              <FormControl
                /* error={!formData?.tarifacaoExcedente?.valor && Null} */
                size="small"
                variant="filled"
                sx={styles.valorMbps}
              >
                <InputLabel
                  helperText={
                    !formDataConfigPercentil?.tarifacaoFixa && Null
                      ? "Necessário informar valor por Mbps"
                      : ""
                  }
                  error={!formDataConfigPercentil?.tarifacaoFixa && Null}
                >
                  Valor por Mbps
                </InputLabel>
                <FilledInput
                  value={formDataConfigPercentil?.tarifacaoFixa || ""}
                  onChange={handleValorConfig}
                  startAdornment={
                    <InputAdornment position="start">R$</InputAdornment>
                  }
                  disabled={editConfig}
                  helperText={
                    !formDataConfigPercentil?.tarifacaoFixa && Null
                      ? "Necessário informar valor por Mbps"
                      : ""
                  }
                  error={!formDataConfigPercentil?.tarifacaoFixa && Null}
                />
              </FormControl>

              <FormControl size="small" variant="filled" sx={{ width: "100%" }}>
                <InputLabel
                  helperText={
                    !formDataConfigPercentil?.valorExcedente && Null
                      ? "Necessário informar valor excedente"
                      : ""
                  }
                  error={!formDataConfigPercentil?.valorExcedente && Null}
                >
                  Limite de tráfego
                </InputLabel>
                <FilledInput
                  value={formDataConfigPercentil?.valorExcedente || ""}
                  onChange={handleLimiteTráfegoConfig}
                  disabled={editConfig}
                />
                <FormHelperText id="filled-weight-helper-text">
                  Em Mbps
                </FormHelperText>
              </FormControl>
              <FormControl size="small" variant="filled" sx={{ width: "100%" }}>
                <InputLabel
                  helperText={
                    !formDataConfigPercentil?.tarifacaoExcedente && Null
                      ? "Necessário informar tarifa excedente"
                      : ""
                  }
                  error={!formDataConfigPercentil?.tarifacaoExcedente && Null}
                >
                  Excedente em %
                </InputLabel>
                <FilledInput
                  value={formDataConfigPercentil?.tarifacaoExcedente || ""}
                  onChange={handleValorExcedenteConfig}
                  disabled={editConfig}
                />
                <FormHelperText id="filled-weight-helper-text">
                  R$ {valorExecedente}
                </FormHelperText>
              </FormControl>
            </Box>
            <Divider sx={styles.divider}></Divider>
            <Box sx={styles.containerPermissao}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  disabled={editConfig}
                  checked={formDataConfigPercentil?.permissao}
                  onChange={(e) =>
                    setFormDataConfigPercentil((prev) => ({
                      ...prev,
                      permissao: e.target.checked,
                    }))
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography>
                  Permitir que este cliente possa gerar relatórios.
                </Typography>
              </Box>

              <FormHelperText sx={{ ml: 1.5 }}>
                O cliente está{" "}
                {formDataConfigPercentil?.permissao
                  ? "habilitado"
                  : "desabilitado"}{" "}
                para gerar relatórios
              </FormHelperText>
            </Box>
            <Divider sx={styles.divider}></Divider>
            <Stack spacing={2} alignItems={"center"} direction={"row"}>
              {editConfig ? (
                <Stack
                  spacing={2}
                  alignItems={"end"}
                  sx={styles.containerButton}
                >
                  <Button
                    onClick={handleEditConfig}
                    variant="contained"
                    size="medium"
                  >
                    Editar
                  </Button>
                </Stack>
              ) : (
                <Stack
                  spacing={2}
                  alignItems={"end"}
                  sx={styles.containerButton}
                >
                  <Box sx>
                    <LoadingButton
                      sx={styles.button}
                      onClick={() => {
                        validacaoConfig();
                      }}
                      variant="contained"
                      size="medium"
                      disabled={loadingConfig}
                    >
                      Salvar
                    </LoadingButton>

                    <Button
                      sx={styles.button}
                      onClick={handleCancelarConfig}
                      size="medium"
                      /*      disabled={loading} */
                    >
                      Cancelar
                    </Button>
                  </Box>
                </Stack>
              )}
            </Stack>
          </Box>
        </Paper>
      </Paper>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
