const styles = (props) => ({
  modal: { display: "flex", alignItems: "center", justifyContent: "center" },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // bgcolor: "primary.main",
    gap: "16px",
    padding: "8px",
    borderRadius: "12px",
    position: "relative",
    "@media(max-width: 600px)": { width: "300px" },
    bgcolor: "#212B36",
  },
  title: { marginTop: "8px", color: "#FFFFFF" },
  iconButton: {
    position: "absolute",
    right: 16,
    top: 16,
    color: "white",
    padding: 0,
  },
  avatar: {
    position: "absolute",
    left: 30,
    top: 15,
    height: "100px",
    width: "100px",
    border: `solid 4px`,
    borderColor: "#212B36",
    "@media(max-width: 600px)": {
      height: "70px",
      width: "70px",
      top: 35,
    },
  },
  content: {
    bgcolor: "background.default",
    display: "flex",
    flexDirection: "column",
    padding: "8px 10px 20px 10px",
    gap: "16px",
    borderRadius: "10px",
    "@media(max-width: 600px)": {
      width: 1,
      alignItems: "center",
    },
  },
  buttonSave: {
    color: "primary.main",
  },
  buttonCancelar: {
    color: "secondary.main",
  },
  TextField: {
    fontSize: "16px",
    fontWeight: "600",
    width: "400px",
  },
});

export default styles;
