const styles = (theme) => ({
  pageTopSpace: {
    display: "flex",
    width: "100%",
    position: "stick",
    justifyContent: "center",
    top: 0,
    alignItems: "center",
    height: "40px",
    "@media (max-width: 681px)": {
      height: "60px",
    },
    //mb: "40px",
  },
  contentBox: {
    display: "flex",
    width: "100%",
    position: "fixed",
    justifyContent: "center",
    top: 0,
    zIndex: 99,
    backgroundColor: "chip.inProgress",
    height: "40px",
    "@media (max-width: 681px)": {
      height: "65px",
    },
  },
  boxButton: {
    flexDirection: "row",
    display: "flex",
    width: "100%",
    pl: "50px",
    pr: "60px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  returnBt: {
    color: "#ffffff",
    "@media (max-width: 760px)": {
      fontSize: "11px",
      textAlign: "center",
    },
    "&.MuiButton-root": {
      borderColor: "#ffffff",
    },
  },
  helperTitle: {
    color: "#ffffff",
    ml: "20px",
    fontWeight: 800,
    "@media (max-width: 760px)": {
      fontSize: "14px",
      textAlign: "center",
    },
  },
});

export default styles;
