import { Text, View } from "@react-pdf/renderer";
import { styles } from "./../styles";
import {
  convertBitsToBytesBin,
  convertBitsToBytes,
  tarifacaoMBitsPorSegundo,
  converteUnidades,
  calculoExcedente,
} from "../../../utils/flowReport";
const TraficRows = ({
  trafficFull,
  averageValue,
  c95th,
  name,
  price,
  excess,
  limitTraffic,
  tarifacaoExcedente,
}) => (
  <>
    <View style={styles.tableCell}>
      <Text>
        {convertBitsToBytesBin(trafficFull) +
          " / " +
          convertBitsToBytes(trafficFull)}
      </Text>
    </View>
    <View style={styles.tableCell}>
      <Text>{converteUnidades(averageValue, "bps")}</Text>
    </View>
    <View style={styles.tableCell}>
      <Text>{converteUnidades(c95th, "bps")}</Text>
    </View>
    {tarifacaoExcedente?.status ? (
      <View style={styles.tableCell}>
        <Text>{`${Math.max(
          calculoExcedente(
            tarifacaoExcedente?.valor,
            c95th,
            tarifacaoExcedente?.valorExcedente,
            tarifacaoExcedente?.limite
          ),
          tarifacaoExcedente?.valor * tarifacaoExcedente?.limite
        ).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}`}</Text>
      </View>
    ) : (
      <View style={styles.tableCell}>
        <Text>{`${Math.max(
          tarifacaoMBitsPorSegundo(c95th, tarifacaoExcedente?.valor),
          tarifacaoExcedente?.valor * tarifacaoExcedente?.limite
        ).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}`}</Text>
      </View>
    )}
  </>
);
const TraficRowsResult = ({ name, valorTotal }) => (
  <>
    <View style={styles.tableCell}></View>
    <View style={styles.tableCell}></View>
    <View style={styles.tableCell}></View>
    <View style={[styles.tableCell, { fontFamily: "Times-Bold" }]}>
      <Text>{name}</Text>
    </View>
    <View style={[styles.tableCell, { fontFamily: "Times-Bold" }]}>
      <Text>{`${valorTotal.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      })}`}</Text>
    </View>
  </>
);
const BooleanRows = ({ availability, name }) => (
  <>
    <View style={styles.tableCell}>
      <Text>{name}</Text>
    </View>
    <View style={styles.tableCell}>
      <Text>{availability?.availability}</Text>
    </View>
    <View style={styles.tableCell}>
      <Text>{availability?.uptime}</Text>
    </View>
    <View style={styles.tableCell}>
      <Text>
        {(
          100.0 - parseFloat(availability?.availability.replace("%", ""))
        ).toFixed(2) + "%"}
      </Text>
    </View>
    <View style={styles.tableCell}>
      <Text>{availability?.downtime}</Text>
    </View>
  </>
);

const hosts = [
  {
    type: "noPacket",
    minValue: 372800,
    maxValue: 8996200779,
    avgValue: 1076463856.9548612,
    trafficFull: 93006477240900,
    th95: "8220835839 bps",
  },
];

export function TableReport({
  title,
  hosts,
  tag = "",
  headerTitles,
  tarifacaoExcedente,
  indiceTable,
  index,
  tipo = 0,
}) {
  let valorTotal = 0.0;
  return (
    <View>
      <View wrap={false}>
        <Text style={styles.subtitle}>
          {/* {tipo === 0
            ? tag === "traffic"
              ? `Host ${index + 1}: ${title}`
              : `Item ${index + 1}: ${title}`
            : null} */}
          {tipo === 0
            ? tag === "traffic"
              ? `Host : ${title}`
              : `Item : ${title}`
            : null}
          {/* {tipo === 1 ? `Cliente ${index + 1}: ${title}` : null} */}
          {tipo === 1 ? `Cliente : ${title}` : null}
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 5,
          }}
        >
          <Text style={styles.legendBold}>{`Tabela ${indiceTable}: `}</Text>

          <Text
            style={styles.legend}
          >{`Resumo dos dados coletados para ${title}`}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            {headerTitles.map((value, index) => (
              <View key={"table-cell" + index} style={styles.tableCell}>
                <Text> {value}</Text>
              </View>
            ))}
          </View>
          {hosts?.map(
            (
              {
                itemName,
                hostName,
                availability,
                trafficFull,
                averageValue,
                c95th,
                price,
                avgValue,
                th95,
                type,
                excess,
                limitTraffic,
              },
              indexRow
            ) => {
              console.log(
                itemName,
                hostName,
                availability,
                trafficFull,
                averageValue,
                c95th,
                price,
                avgValue,
                th95,
                type,
                excess,
                limitTraffic
              );
              return (
                <View key={"table-row" + indexRow} style={styles.tableRow}>
                  {tag === "boolean" && availability ? (
                    <BooleanRows availability={availability} name={hostName} />
                  ) : tag === "traffic" &&
                    (!!trafficFull || trafficFull == 0) &&
                    (!!averageValue || averageValue == 0) &&
                    (!!c95th || c95th == 0) ? (
                    <>
                      <TraficRows
                        name={itemName}
                        trafficFull={trafficFull}
                        averageValue={averageValue}
                        c95th={c95th}
                        price={price}
                        excess={excess}
                        limitTraffic={limitTraffic}
                      />
                      {excess
                        ? (valorTotal += calculoExcedente(
                            price,
                            c95th,
                            excess,
                            limitTraffic
                          ))
                        : (valorTotal += tarifacaoMBitsPorSegundo(
                            c95th,
                            price
                          ))}
                    </>
                  ) : (!!trafficFull || trafficFull == 0) &&
                    (!!avgValue || avgValue == 0) &&
                    (!!th95 || th95 == 0) ? (
                    <>
                      <TraficRows
                        name={type}
                        trafficFull={trafficFull}
                        averageValue={avgValue}
                        c95th={th95}
                        price={price}
                        tarifacaoExcedente={tarifacaoExcedente}
                      />
                      {(valorTotal += tarifacaoMBitsPorSegundo(th95, price))}
                    </>
                  ) : null}
                </View>
              );
            }
          )}
          {!!valorTotal ? (
            <View style={styles.tableRow}>
              <TraficRowsResult name={"Total"} valorTotal={valorTotal} />
            </View>
          ) : null}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Text
            style={[styles.legend, { textAlign: "center" }]}
          >{`Fonte: Dados coletados pelo ${
            tipo === 0 ? "Zabbix" : "EasyFlow"
          }`}</Text>
        </View>
      </View>
    </View>
  );
}
