import {
  Autocomplete,
  Avatar,
  Checkbox,
  Chip,
  Divider,
  Grid,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import useStyles from "./styles";
import SubDetails from "./specificTables";
import dayjs from "dayjs";

export default function ModalDetails(params) {
  const styles = useStyles();
  const {
    openDetalhes = false,
    setOpenDetalhes = () => {},
    selectedRow = {},
  } = params;
  // Lista de tabelas com estilização própria
  const tabelasPersonalizadas = [
    "Perfil",
    "Comunidades",
    "Outros",
    "Planos",
    "Prefixos",
    "Solicitações",
    "Vlan",
    "Túnel",
  ]; // Terão carregamento pelo componente SubDetail

  // Arrays para receber um flatMap dos objetos from,to e objetos aninhados
  let flatFrom = [];
  let flatTo = [];
  // Lista de campos que não deverão ser exibidos na renderição genérica
  const camposOcultos = [
    "id",
    "createAt",
    "updatedAt",
    "clienteId",
    "status",
    "plano",
    "diaDePagamento",
    "tipo",
    "cliente",
    "createdAt",
    "usuarioId",
    "foto",
  ];

  function getKeys(obj) {
    // Retorna um array de objetos
    return Object.keys(obj).flatMap((k) => {
      // && !obj[k].length
      return typeof obj[k] == "object"
        ? // Se o item for um objeto ele é passado para a mesma função
          // recursividade necessária para mapear objetos aninhados
          getKeys(obj[k] || {}) // || {} evita erros com valores null/ undefined
        : { [k]: obj[k] }; //
    });
  }

  if (selectedRow?.from) {
    // Chama função getKeys e recebe array de objetos[{},{}]
    if (Array.isArray(selectedRow?.from)) {
      const arrFlatObj = selectedRow?.from?.map((item) => getKeys(item));
      flatFrom = arrFlatObj?.map((item) =>
        // Remove as {} em cada objeto do array
        // E adiciona eles em um único objeto
        Object.assign.apply({}, [{}].concat(item))
      );
    } else {
      const flatObj = getKeys(selectedRow?.from);
      // Remove as {} em cada objeto do array
      // E adiciona eles em um único objeto
      flatFrom = [Object.assign.apply({}, [{}].concat(flatObj))];
    }
  }

  if (selectedRow?.to) {
    // Chama função getKeys e recebe array de objetos[{},{}]
    if (Array.isArray(selectedRow?.to)) {
      const arrFlatObj = selectedRow?.to?.map((item) => getKeys(item));
      flatTo = arrFlatObj?.map((item) =>
        // Remove as {} em cada objeto do array
        // E adiciona eles em um único objeto
        Object.assign.apply({}, [{}].concat(item))
      );
    } else {
      const flatObj = getKeys(selectedRow?.to);
      // Remove as {} em cada objeto do array
      // E adiciona eles em um único objeto
      flatTo = [Object.assign.apply({}, [{}].concat(flatObj))];
    }
  }

  // Divide string por letras maiúsculas
  const wordRegex = /[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g;

  return (
    <>
      {tabelasPersonalizadas?.includes(selectedRow?.table) ? (
        // Se existir estilização específica para a tabela
        <SubDetails
          openDetalhes={openDetalhes}
          setOpenDetalhes={setOpenDetalhes}
          selectedRow={selectedRow}
        />
      ) : (
        // Caso contrário exibirá de forma genérica com TextFields
        <Modal
          open={openDetalhes}
          onClose={() => {
            setOpenDetalhes(false);
          }}
          sx={styles.modal}
        >
          <Paper sx={styles.paper}>
            {/* informações do usuario */}
            <Box sx={styles.header}>
              <Avatar src={selectedRow?.user?.foto || ""} sx={styles.avatar} />
              <Box sx={styles.headerContent}>
                <Typography variant="title">
                  {selectedRow?.user?.nome} {selectedRow?.user?.sobrenome}
                </Typography>
                <Box sx={styles.headerLine}>
                  <Box sx={styles.headerItem}>
                    <Typography variant="caption" sx={styles.headerSubtitle}>
                      Navegador
                    </Typography>
                    <Typography sx={styles.headerText}>
                      {selectedRow?.session?.navegador}
                    </Typography>
                  </Box>
                  <Box sx={styles.headerItem}>
                    <Typography variant="caption" sx={styles.headerSubtitle}>
                      Sistema
                    </Typography>
                    <Typography sx={styles.headerText}>
                      {selectedRow?.session?.sistemaOperacional}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={styles.headerLine}>
                  <Box sx={styles.headerItem}>
                    <Typography variant="caption" sx={styles.headerSubtitle}>
                      IP
                    </Typography>
                    <Typography sx={styles.headerText}>
                      {selectedRow?.session?.enderecoIp}
                    </Typography>
                  </Box>
                  {selectedRow?.cliente ? (
                    <Box sx={styles.headerItem}>
                      <Typography variant="caption" sx={styles.headerSubtitle}>
                        Cliente
                      </Typography>
                      <Typography sx={styles.headerText}>
                        {selectedRow?.cliente}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
                <Box sx={styles.headerItem}>
                  <Typography variant="caption" sx={styles.headerSubtitle}>
                    Hora
                  </Typography>
                  <Typography sx={styles.headerText}>
                    {selectedRow?.time}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            {/* Conteudo da mudança */}
            <Box sx={styles.content}>
              {/* De */}
              <Box sx={styles.contentHeader}>
                {
                  <Typography>
                    {selectedRow?.from && selectedRow?.action === "Edição"
                      ? "De: "
                      : ""}
                  </Typography>
                }
                <Chip
                  sx={{
                    bgcolor:
                      selectedRow?.action === "Criação"
                        ? "success.main"
                        : selectedRow?.action === "Edição"
                        ? "secondary.main"
                        : "chip.pending",
                  }}
                  label={selectedRow?.action}
                />
              </Box>
              <Box sx={styles.boxMap}>
                {flatFrom?.map((item) =>
                  Object.keys(item)?.map(
                    (key) =>
                      !camposOcultos?.includes(key) && (
                        <Box key={key} sx={styles.boxTextField}>
                          <TextField
                            variant="filled"
                            label={
                              key[0].toUpperCase() +
                              key.substring(1).match(wordRegex).join(" ")
                            }
                            value={item[key]}
                            size="small"
                            fullWidth
                            disabled={true}
                          />
                        </Box>
                      )
                  )
                )}
              </Box>
              {/* Para */}
              {selectedRow?.action === "Edição" && selectedRow?.to && (
                <Typography>Para:</Typography>
              )}
              <Box sx={styles.boxMap}>
                {flatTo?.map((item) =>
                  Object.keys(item)?.map(
                    (key) =>
                      !camposOcultos?.includes(key) && (
                        <Box key={key} sx={styles.boxTextField}>
                          <TextField
                            variant="filled"
                            label={
                              key[0].toUpperCase() +
                              key.substring(1).match(wordRegex).join(" ")
                            }
                            value={item[key]}
                            size="small"
                            fullWidth
                            disabled={true}
                          />
                        </Box>
                      )
                  )
                )}
              </Box>
            </Box>
          </Paper>
        </Modal>
      )}
    </>
  );
}
