import { View, Text, Link } from "@react-pdf/renderer";
import { styles } from "./styles";
// Estouro obitido a partir da diferença do percentil
export const Teoric = ({ hideCharts = false, percentil = 95 }) => (
  <>
    <View
      break
      style={{
        display: "flex",
        gap: 1,
        flexWrap: "wrap",
      }}
    >
      <Text style={styles.title}>{"EMBASAMENTO TEÓRICO "}</Text>

      <Text style={styles.subtitle}>
        {`HDR histogram para cálculo do 95 Percentil:`}
      </Text>
      <Text style={[styles.text, { textIndent: "20px" }]}>
        {`O percentil 95 emerge como uma ferramenta fundamental na análise de tráfego de rede, proporcionando uma avaliação robusta e confiável ao desconsiderar valores extremos, como picos de uso gerado em uma interface. A utilização dessa métrica oferece uma visão estável e representativa do tráfego típico, sendo instrumental na identificação de períodos de elevada demanda, contribuindo para o monitoramento de Acordos de Nível de Serviço (SLAs) e assegurando que os serviços atendam consistentemente às expectativas dos usuários finais (MANAGE ENGINE, 2022).`}
      </Text>
      <Text style={[styles.text, { textIndent: "20px" }]}>
        {`Este valor representa a parte mais alta dos dados que não são descartados quando os 5% superiores são removidos. Geralmente é utilizado como uma medida do pico máximo, especialmente ao desconsiderar valores extremos transitórios. Essa abordagem contrasta significativamente com a média, fornecendo uma perspectiva distinta sobre a distribuição dos dados (ANDERSON et al., 2018).`}
      </Text>
      <Text style={[styles.text, { textIndent: "20px" }]}>
        {`O método de histograma HDR (High Dynamic Range) é uma técnica utilizada para calcular o percentil 95 de um conjunto de dados, especialmente em situações onde há uma grande variação na distribuição dos valores. Este método é útil na análise estatística e outras aplicações onde a representação de uma ampla gama de valores é necessária.`}
      </Text>
      <Text style={[styles.text, { textIndent: "20px" }]}>
        {`O histograma é uma representação gráfica da distribuição de frequência dos valores de um conjunto de dados. No contexto do método de histograma HDR, o histograma é utilizado para dividir os dados em intervalos discretos e contar quantas ocorrências de valores estão presentes em cada intervalo.`}
      </Text>
      <Text style={[styles.text, { textIndent: "20px" }]}>
        {`Para calcular o percentil 95 usando o método de histograma HDR, primeiro é necessário construir o histograma dos dados. Em seguida, os intervalos do histograma são percorridos, mantendo uma contagem acumulada das ocorrências de valores. O percentil 95 é então calculado determinando o ponto no qual a contagem acumulada alcança 95% do total de ocorrências de valores.`}
      </Text>
      <Text style={styles.subtitle}>
        {
          "Distinção Entre Kilobyte (KB) e Kibibyte (KiB) no Contexto de Transmissão de Dados:"
        }
      </Text>
      <Text style={[styles.text, { textIndent: "20px" }]}>
        {`No sistema de armazenamento digital, há uma distinção entre kilobyte (KB) e kibibyte (KiB), assim como entre megabyte (MB) e mebibyte (MiB). O KB (kilobyte) é uma unidade baseada no sistema decimal, equivalente a 1000 bytes. Por outro lado, o KiB (kibibyte) é uma unidade baseada no sistema binário, equivalente a 1024 bytes.`}
      </Text>
      <Text style={[styles.text, { textIndent: "20px" }]}>
        {`Essa diferença decorre das bases numéricas utilizadas em cada sistema: base 10 para o decimal e base 2 para o binário, o que pode gerar discrepâncias significativas em contextos de armazenamento de dados e transferência de informação, onde a precisão é fundamental. `}
      </Text>
      <Text style={[styles.text, { textIndent: "20px" }]}>
        {`Para evitar ambiguidades, foram introduzidos os prefixos binários (Ki, Mi, Gi, etc.) pelo Comitê Internacional de Pesos e Medidas (CIPM), fornecendo medidas mais precisas em termos de capacidade de armazenamento e transferência de dados em contextos técnicos (INTERNACIONAL ELECTROTECHNICAL COMMISSION, 2008).`}
      </Text>
    </View>
    <View
      styles={{
        display: "flex",
        gap: 5,
      }}
    >
      <Text style={styles.title}>{"REFERÊNCIAS "}</Text>
      <Text style={[styles.text, { margin: 4 }]}>
        {
          "TAN,Pang-Ning;STEINBACH,Michael;KUMAR,Vipin. Introduction to Data Mining. 2ª ed. Estados Unidos da América: Person, 2018"
        }
      </Text>
      <Text style={[styles.text, { margin: 4 }]}>
        {"95th Percentile Calculation, Manage Engine. Disponível em:<"}
        <Link
          style={{ color: "00000" }}
          src="https://www.manageengine.com/eu/network-monitoring/faq/95th-percentile-calculation.html"
        >
          https://www.manageengine.com/eu/network-monitoring/faq/95th-percentile-calculation.html
        </Link>
        {">. Acesso em: 7 fev. 2024."}
      </Text>
      <Text style={[styles.text, { margin: 4 }]}>
        {
          "ANDERSON, David et al. Statistics for Business e Economics. 13ª ed. Índia: Cengage Learning India Pvt. Ltd, 2018  "
        }
      </Text>
      <Text style={[styles.text, { margin: 4 }]}>
        {
          "IEC 80000-13: Quantities and units - Part 13: Information science and technology. Geneva: International Electrotechnical Commission, 2008. Disponível em: <"
        }
        <Link
          style={{ color: "00000" }}
          src=" https://webstore.iec.ch/preview/info_iec80000-13%7Bed1.0%7Db.pdf"
        >
          https://webstore.iec.ch/preview/info_iec80000-13%7Bed1.0%7Db.pdf
        </Link>
        {"> Acesso em:  20 dez. 2023."}
      </Text>
    </View>
  </>
);
