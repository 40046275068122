import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../styles";
import {
  convertBitsToBytes,
  convertBitsToBytesBin,
  converteUnidades,
  tarifacaoMBitsPorSegundo,
  calculoExcedente,
} from "../../../utils/flowReport";
import { Fragment } from "react";

// Estiliza as linhas do formato tilo: informação
const RowTrafic = ({ topic, content }) =>
  !!content || content == 0 ? (
    <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
      <Text style={styles.textBold}>{topic}</Text>
      <Text style={styles.text}>{content}</Text>
    </View>
  ) : null;

// Tratamento de horario personalizado recebido através de analysis
const RowHorarioPersonalizado = ({ status, horaInicial, horaFinal }) => {
  return (
    <>
      {status ? (
        <RowTrafic
          topic={"Hora personalizada: "}
          content={`${horaInicial} - ${horaFinal}`}
        />
      ) : null}
    </>
  );
};

const RowTarifacao = ({ price, c95th, percentil }) => {
  return (
    <>
      {!!c95th && (
        <RowTrafic
          topic={`Tráfego ${percentil}th percentil : `}
          content={converteUnidades(c95th, "bps")}
        />
      )}
      {!!price ? (
        <>
          <RowTrafic
            topic={"Preço por Mbps: "}
            content={`${price.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}`}
          />
          <RowTrafic
            topic={"Valor individual de tarifacão: "}
            content={`${tarifacaoMBitsPorSegundo(c95th, price).toLocaleString(
              "pt-br",
              {
                style: "currency",
                currency: "BRL",
              }
            )}`}
          />
        </>
      ) : null}
    </>
  );
};
const RowTarifacaoTotal = ({ analysis, tarifacaoExcedente }) => {
  let limiteExcedente,
    trafegoExcedente,
    trafegoSemExcedente,
    trafegoTotal = 0;
  let valorTotal = 0.0;
  const th95ex = analysis[0]?.th95;
  const preco = tarifacaoExcedente?.valor ?? 0;
  const precoExcedente = tarifacaoExcedente?.valorExcedente ?? 0;
  const limitTraffic = tarifacaoExcedente?.limite;

  if (tarifacaoExcedente?.status) {
    limiteExcedente = limitTraffic
      ? parseFloat(limitTraffic) * Math.pow(10, 6)
      : 0;
    trafegoTotal = th95ex ? parseFloat(th95ex.replace(" bps")) : "";
    trafegoSemExcedente =
      trafegoTotal <= limiteExcedente ? th95ex : limiteExcedente;
    trafegoExcedente =
      trafegoTotal - limiteExcedente < 0 ? 0 : trafegoTotal - limiteExcedente;
    valorTotal = Math.max(
      calculoExcedente(preco, th95ex, precoExcedente, limitTraffic),
      preco * limitTraffic
    );
  } else {
    const preco = tarifacaoExcedente?.valor ?? 0;
    trafegoTotal = th95ex ? parseFloat(th95ex.replace(" bps")) : "";

    valorTotal = Math.max(
      tarifacaoMBitsPorSegundo(preco, trafegoTotal),
      preco * limitTraffic
    );
  }

  return (
    <>
      {tarifacaoExcedente?.status ? (
        <>
          <RowTrafic
            topic={"Preço por Mbps:  "}
            content={`${preco?.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}`}
          />
          <RowTrafic
            topic={"Preço por Mbps de tráfego excedente: "}
            content={`${precoExcedente?.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}`}
          />
          <RowTrafic
            topic={"Limite de tráfego sem cobrança de excedente: "}
            content={`${converteUnidades(limiteExcedente, "bps")}`}
          />
          <RowTrafic
            topic={"Valor sem adicional de excedente: "}
            content={`${Math.max(
              tarifacaoMBitsPorSegundo(trafegoSemExcedente, preco),
              preco * limitTraffic
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}`}
          />
          <RowTrafic
            topic={"Valor do excedente: "}
            content={`${tarifacaoMBitsPorSegundo(
              trafegoExcedente,
              precoExcedente
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}`}
          />
        </>
      ) : (
        <>
          <RowTrafic
            topic={"Preço por Mbps:  "}
            content={`${preco?.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}`}
          />
        </>
      )}
      <RowTrafic
        topic={"Valor total: "}
        content={`${valorTotal.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}`}
      />
    </>
  );
};

const stylesFlowReport = StyleSheet.create({
  columnView: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  rowView: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
  image: {
    height: "auto",
    width: "100%",
  },
});
export default function FlowReport({
  client,
  interfaces,
  tarifacaoExcedente,
  analysis,
  horaInicial,
  horaFinal,
  dataFinal,
  dataInicial,
  graphcs,
  indiceGraphcs,
  etapa,
  etapas,
  ultimaData,
  index,
  percentil,
}) {
  let countGrafics = 0;
  return (
    <>
      {/* <Text break style={styles.title}>{`Cliente ${
        index + 1
      }: ${client?.toUpperCase()}`}</Text> */}
      <Text
        break
        style={styles.title}
      >{`Cliente : ${client?.toUpperCase()}`}</Text>
      <View style={stylesFlowReport.rowView}>
        <View
          style={[
            stylesFlowReport.columnView,
            tarifacaoExcedente?.status || !graphcs
              ? { width: "100%" }
              : { width: "50%" },
          ]}
        >
          <Text style={[styles.text, { textIndent: "20px" }]}>
            {tarifacaoExcedente?.status
              ? `Resultados de tráfego relacionados ao cliente ${client}. A métricas utilizadas para os cálculos dos valores apresentados são: tráfego total, ${percentil}th (percentil). As coletas do valores apresentados neste relatório correspondem ao período entre ${dataInicial} ${horaInicial} à ${dataFinal} ${horaFinal}.`
              : `Resultados de tráfego relacionados ao cliente ${client}. A métricas utilizadas para os cálculos dos valores apresentados são: tráfego total, ${percentil}th (percentil). As coletas do valores apresentados neste relatório correspondem ao período entre ${dataInicial} ${horaInicial} à ${dataFinal} ${horaFinal}.`}
          </Text>
        </View>
        {!tarifacaoExcedente?.status && graphcs && (
          <View style={stylesFlowReport.columnView}>
            <View
              style={[
                stylesFlowReport.rowView,
                {
                  flexWrap: "wrap",
                },
              ]}
            >
              <Text
                style={styles.legendBold}
              >{`Gráfico ${indiceGraphcs[0]}: `}</Text>
              <Text
                style={styles.legend}
              >{`Percentual no valor total do tráfego. `}</Text>
            </View>

            <Image
              key={"iamge1" + index}
              style={stylesFlowReport.image}
              src={`data:application/pdf;base64,${
                graphcs.find((graphc) => graphc.type === "all").graphcs
              }`}
            />

            <View style={stylesFlowReport.rowView}>
              <Text
                style={styles.legend}
              >{`Fonte: Dados coletados pelo EasyFlow`}</Text>
            </View>
          </View>
        )}
      </View>

      {/* TRECHO REFERENTE A DESCRIÇÃO DE VERÃO IP, PREÇOS E VALORES */}
      <View
        key={"boolean-top" + index}
        style={[
          stylesFlowReport.rowView,
          {
            marginVertical: 5,
            justifyContent: "flex-start",
          },
        ]}
        wrap={false}
      >
        <View
          style={[
            stylesFlowReport.columnView,
            {
              flexWrap: "wrap",
            },
          ]}
        >
          {interfaces ? (
            <RowTrafic topic={"Versão IP: "} content={interfaces.join(", ")} />
          ) : null}
          <RowTarifacaoTotal
            analysis={analysis}
            tarifacaoExcedente={tarifacaoExcedente}
          />
        </View>
      </View>

      {/* TRECHO REFERENTE A RESULTADOS NO CORPO DA SEGUNDA PÁGINA */}
      {analysis?.map(
        (
          {
            type,
            maxValue,
            minValue,
            avgValue,
            th95,
            trafficFull,
            history,
            timeCustom,
            price,
          },
          index
        ) => {
          return (
            <Fragment key={"boolean-viewq" + index}>
              <View wrap={false}>
                {!tarifacaoExcedente?.status && (
                  <Text style={styles.subtitle}>{`Resultados:`}</Text>
                )}
                {tarifacaoExcedente?.status && (
                  <Text style={styles.subtitle}>{`Resultados`}</Text>
                )}
                <View
                  key={"boolean-top" + index}
                  style={[
                    stylesFlowReport.rowView,
                    {
                      marginBottom: 10,
                    },
                  ]}
                  wrap={false}
                >
                  <View
                    style={[
                      stylesFlowReport.columnView,
                      {
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    <RowTrafic
                      topic={"Período do relatório: "}
                      content={
                        etapa === etapas && timeCustom?.status
                          ? `${dataInicial} ${timeCustom.horaInicial} à ${ultimaData} ${timeCustom.horaFinal}`
                          : timeCustom?.status
                          ? `${dataInicial} ${timeCustom.horaInicial} à ${dataFinal} ${timeCustom.horaFinal}`
                          : `${dataInicial} ${horaInicial} à ${dataFinal} ${horaFinal}`
                      }
                    />
                  </View>
                  <View
                    style={[
                      stylesFlowReport.columnView,
                      {
                        zIndex: 100,
                        flexWrap: "wrap",
                      },
                    ]}
                  >
                    {trafficFull ? (
                      <RowTrafic
                        topic={"Tráfego total: "}
                        content={
                          convertBitsToBytesBin(trafficFull) +
                          " / " +
                          convertBitsToBytes(trafficFull)
                        }
                      />
                    ) : null}
                    <RowTarifacao
                      c95th={th95}
                      price={price?.valor}
                      percentil={percentil}
                    />
                    <RowHorarioPersonalizado {...timeCustom} />
                  </View>
                </View>
                {/* {graphcs && (
                <View
                  key={"boolean-top2" + index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginVertical: 10,
                  }}
                  wrap={false}
                >
                  <View style={stylesFlowReport.rowView}>
                    {tarifacaoExcedente?.status ? (
                      <Text style={styles.legendBold}>{`Gráfico ${
                        indiceGraphcs[0] + countGrafics
                      }: `}</Text>
                    ) : (
                      <Text style={styles.legendBold}>{`Gráfico ${
                        indiceGraphcs[1] + countGrafics
                      }: `}</Text>
                    )}
                    {tarifacaoExcedente?.status ? (
                      <Text
                        style={styles.legend}
                      >{`95th percentil do cliente ${client}`}</Text>
                    ) : (
                      <Text
                        style={styles.legend}
                      >{`95th percentil do cliente ${client} para a classe ${type} no período selecionado. `}</Text>
                    )}
                  </View>
                  <Image
                    key={"iamge2" + index}
                    style={{ height: "auto", width: "500px" }}
                    src={`data:application/pdf;base64,${
                      graphcs.find((graphc) => graphc.type == type).graphcs
                    }`}
                  />
                  {(countGrafics += 1)}
                  <View style={stylesFlowReport.rowView}>
                    <Text
                      style={styles.legend}
                    >{`Fonte: Dados coletados pelo EasyFlow`}</Text>
                  </View>
                </View>
              )} */}
              </View>
            </Fragment>
          );
        }
      )}
    </>
  );
}
