import { useTheme } from "@mui/system";
import useStyles from "./styles";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useEffect } from "react";

export default function ClientViewpoint() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate();

  const path = window.location.pathname;
  const isMirror = path.includes("mirror");
  const isInternal = Boolean(localStorage.getItem("user"));
  if (isInternal && !isMirror) {
    window.history.replaceState(null, null, `mirror${path}`);
  }

  return (
    <>
      {isMirror && (
        <>
          <Box sx={styles.pageTopSpace}></Box>
          <Box sx={styles.contentBox}>
            <Box sx={styles.boxButton}>
              <Button
                onClick={() => {
                  localStorage.removeItem("logInternoCliente");
                  localStorage.removeItem("userCliente");
                  localStorage.removeItem("perfilCliente");
                  navigate("/interno/administracao");
                }}
                variant="outlined"
                startIcon={<ArrowBack />}
                size="small"
                sx={styles.returnBt}
              >
                RETORNAR AO MODO NORMAL
              </Button>
              <Typography sx={styles.helperTitle}>
                MODO DE VISUALIZAÇÃO DA TELA DO CLIENTE!
              </Typography>
            </Box>
          </Box>
        </>
      )}

      <Outlet />
    </>
  );
}
