import { HorizontalRule } from "@mui/icons-material";
import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

export default function LineChartComponent({
  percentil = 95,
  percentile95 = 0,
  maxValuebps = 0,
  data = {},
  tituloLimite = 0,
  valorLimite = 0,
}) {
  // Estilização
  const theme = useTheme();
  const unidade = "bps";

  // Conversão de dados
  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 B";
    const k = 1000;
    const sizes = ["bps", "Kbps", "Mbps", "Gbps", "Tbps", "Pbps", "Ebps"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          height: "20px",
          width: "110px",
          bottom: "4px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <HorizontalRule sx={{ color: "red", height: "20px" }} />
        <Typography
          sx={{ fontSize: "13px", color: "theme.palette.primary.main" }}
        >
          {percentil}th percentil
        </Typography>
      </Box>
      <AreaChart
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 10,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(date, index) => dayjs.unix(date).format("DD/MM")}
          interval={50}
          style={{ fontSize: "12px", fontWeight: 500 }}
          tick={{ fill: theme.palette.primary.main, opacity: 0.7 }}
        />
        <YAxis
          tickFormatter={formatBytes}
          interval={0}
          domain={[0, maxValuebps]}
          tick={{ fill: theme.palette.primary.main, opacity: 0.7 }}
          style={{ fontSize: "12px", fontWeight: 500 }}
        />
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              return (
                <Box
                  sx={{
                    bgcolor:
                      theme.palette.mode === "dark" ? "#07070752" : "#ffffffb4",
                    padding: "8px",
                  }}
                >
                  <Typography fontSize={"14px"}>{`Data: ${dayjs
                    .unix(label)
                    .format("DD/MM HH:mm")}`}</Typography>

                  <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
                    <Box
                      sx={{
                        bgcolor: "#00A76F",
                        width: "14px",
                        height: "14px",
                        borderRadius: "2px",
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                    />
                    <Typography
                      fontSize={"14px"}
                    >{`Tráfego Limpo: ${formatBytes(
                      payload[0].payload.cleanTraffic
                    )}`}</Typography>
                  </Stack>
                </Box>
              );
            }

            return null;
          }}
        />

        <Area
          type="monotone"
          dataKey="cleanTraffic"
          stroke={theme.palette.mode === "dark" ? "#61ffca" : "#027951"}
          name="Tráfego"
          strokeWidth={2}
          fill="#00A76F"
          fillOpacity="1"
          stackId={1}
          dot={false}
        />
        {percentile95 && (
          <>
            <ReferenceLine
              y={percentile95}
              // label={{
              //   value: `${percentil}th percentil`,
              //   position: "insideRight",
              //   fill: theme.palette.primary.main,
              //   fontSize: "13px",
              //   fontWeight: 500,
              //   transform: "translate(0 -10)",
              // }}
              stroke="red"
              strokeWidth={1.5}
              //strokeDasharray="50 4"
            />
          </>
        )}
      </AreaChart>
    </ResponsiveContainer>

    //   </Box>
    // </Box>
  );
}
