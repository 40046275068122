import {
  Modal,
  Box,
  Typography,
  Button,
  Paper,
  Avatar,
  useTheme,
  IconButton,
  Stack,
  TextField,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";
import { useEffect, useState } from "react";

import api from "../../services/api";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InternoModalDeleteCliente from "../internoModalDeleteCliente";

export default function ModalEditSenhaUserLogado({
  open = false,
  handleClose = () => {},
  setSnackbar = {},
}) {
  // Estilização
  const theme = useTheme();
  const styles = useStyles(theme);
  // Variáveis da manipulação de senha
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [senhaAtual, setSenhaAtual] = useState("");
  const [senhaAtualErrada, setSenhaAtualErrada] = useState(false);
  const [senha, setSenha] = useState("");
  const [senhaValid, setSenhaValid] = useState(false);
  const [Null, setNull] = useState(false);
  const [confirmSenha, setConfirmSenha] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  // limpeza dos campos ao abrir o modal
  useEffect(() => {
    setShowPassword(false);
    setNull(false);
    setSenhaAtualErrada(false);
    setSenhaValid(false);
    setSenhaAtual("");
    setSenha("");
    setConfirmSenha("");
  }, []);

  // Foto de perfil
  const [foto, setFoto] = useState(
    localStorage.getItem("perfil") &&
      JSON.parse(localStorage.getItem("perfil")) != "undefined"
      ? JSON.parse(localStorage.getItem("perfil"))?.foto
      : ""
  );

  // Validar senha
  useEffect(() => {
    if (senha.length >= 6 && /[a-zA-Z]/.test(senha) && /\d/.test(senha))
      setSenhaValid(true);
    else setSenhaValid(false);
  }, [senha]);

  // Confirmar alteração de senha
  const handleUpdate = () => {
    if (
      !senha ||
      !confirmSenha ||
      confirmSenha !== senha ||
      !senhaValid ||
      !senhaAtual ||
      senha === senhaAtual
    ) {
      setNull(true);
      return;
    }

    setOpenConfirmModal(true);
  };

  // Utilização da api para efetuar a troca de senha
  const handleResetSenha = async () => {
    setLoading(true);
    try {
      const response = await api.post("/interno/update/senha", {
        senhaAtual: senhaAtual,
        novaSenha: senha,
      });
      setSnackbar({
        children: "Senha alterada com sucesso",
        severity: "success",
      });
      setShowPassword(false);
      setNull(false);
      setSenhaAtualErrada(false);
      setSenhaValid(false);
      setSenhaAtual("");
      setSenha("");
      setConfirmSenha("");
      handleClose();
    } catch (error) {
      if (error?.response?.status === 401) {
        setSenhaAtualErrada(true);
      } else {
        setSenhaAtualErrada(false);
      }
      console.error("Erro ao trocar senha");
      setSnackbar({
        children: "Erro ao alterar senha",
        severity: "error",
      });
    } finally {
      setOpenConfirmModal(false);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setShowPassword(false);
        setNull(false);
        setSenhaAtualErrada(false);
        setSenhaValid(false);
        setSenhaAtual("");
        setSenha("");
        setConfirmSenha("");
        handleClose();
      }}
      sx={styles.modal}
    >
      <Paper
        sx={styles.container}
        elevation={theme.palette.mode === "dark" ? 0 : 3}
      >
        <Box sx={styles.title}>
          <Typography variant="title" color={"text.textContrast"}>
            Meu Perfil
          </Typography>

          <IconButton
            onClick={() => {
              handleClose();
            }}
            sx={styles.iconButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Avatar sx={styles.avatar} src={foto || ""} />

        <Box sx={styles.content}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                disabled={loading}
                onClick={() => {
                  setSenhaValid(false);
                  setNull(false);
                  handleClose();
                }}
                sx={styles.buttonCancelar}
                variant="text"
              >
                {" "}
                Cancelar
              </Button>
              <Button
                disabled={loading}
                onClick={() => handleUpdate()}
                sx={styles.buttonSave}
                variant="text"
              >
                Alterar senha
              </Button>
            </Box>
          </Box>

          <Stack
            direction={"column"}
            padding={"10px"}
            gap={"25px"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            width={"100%"}
          >
            <Stack direction={"column"} spacing={"10px"} width={"100%"}>
              <Typography>Confirme a senha atual.</Typography>

              <Stack spacing={"10px"}>
                <TextField
                  disabled={loading}
                  variant="filled"
                  label="Senha Atual"
                  value={senhaAtual}
                  type={showPassword ? "text" : "password"}
                  onChange={(event) => {
                    setSenhaAtual(
                      event?.target?.value?.trim()?.replace(" ", "")
                    );
                    setSenhaAtualErrada(false);
                  }}
                  error={(!senhaAtual && Null) || senhaAtualErrada}
                  helperText={
                    !senhaAtual && Null
                      ? "Campo obrigatório."
                      : senhaAtualErrada
                      ? "Senha incorreta"
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event?.preventDefault();
                      document.getElementById("senha").focus();
                    }
                  }}
                />
              </Stack>
            </Stack>
            <Stack direction={"column"} spacing={"10px"} width={"100%"}>
              <Typography>
                Digite a nova senha e confirme em seguida.
              </Typography>
              <Stack spacing={"10px"}>
                <TextField
                  id="senha"
                  disabled={loading}
                  variant="filled"
                  label="Senha"
                  type={showPassword ? "text" : "password"}
                  error={
                    (!senha ||
                      !senhaValid ||
                      senha !== confirmSenha ||
                      senha === senhaAtual) &&
                    Null
                  }
                  value={senha}
                  onChange={(event) => {
                    setSenha(event?.target?.value?.trim()?.replace(" ", ""));
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    (!senha || !senhaValid) && Null
                      ? "A senha precisa ter no mínimo 6 digítos, uma letra e um número."
                      : Null && senha !== confirmSenha
                      ? "As senhas não coincidem."
                      : senha === senhaAtual && Null
                      ? "A nova senha deve ser diferente da atual"
                      : ""
                  }
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      document.getElementById("confirmarSenha").focus();
                    }
                  }}
                />
                <TextField
                  id="confirmarSenha"
                  disabled={loading}
                  sx={styles.TextField}
                  variant="filled"
                  label="Confirmar Senha"
                  error={(!senha && Null) || (Null && senha !== confirmSenha)}
                  type={showPassword ? "text" : "password"}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleUpdate();
                    }
                  }}
                  value={confirmSenha}
                  onChange={(event) => {
                    setConfirmSenha(
                      event?.target?.value?.trim()?.replace(" ", "")
                    );
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    (!senha || !senhaValid) && Null
                      ? "A senha precisa ter no mínimo 6 digítos, uma letra e um número."
                      : Null && senha !== confirmSenha
                      ? "As senhas não coincidem."
                      : ""
                  }
                />
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <InternoModalDeleteCliente
          openDeleteModal={openConfirmModal}
          deleteLoad={false}
          setOpenDeleteModal={setOpenConfirmModal}
          execute={handleResetSenha}
          severity={"warning"}
          contentText={
            "Você está prestes a alterar a senha. Se prosseguir, a senha será modificada permanentemente. Deseja confirmar a alteração?"
          }
          confirmText={"CONFIRMAR"}
        />
      </Paper>
    </Modal>
  );
}
