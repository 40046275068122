import { useTheme } from "@emotion/react";
import "dayjs/locale/pt-br";
import {
  Box,
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "@mui/material";
import api from "../../../../../services/api";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useOutletContext } from "react-router-dom";
import useStyle from "./styles";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/pt-br"; // Importa a localidade para português
import { pdf } from "@react-pdf/renderer";
import { PageTemplateEasyReport } from "../../../../../components/generateReport";
import { saveAs } from "file-saver";
import { LoadingButton } from "@mui/lab";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.locale("pt-br"); // Define a localidade como português

export default function Relatorio({ loading, setLoading = () => {} }) {
  const mesAtual = dayjs().format("MMMM"); // Nome do mês atual
  const mesPassado = dayjs().subtract(1, "month").format("MMMM");

  const today = new Date();
  today?.toLocaleString("en-US", {
    timeZoneName: "longOffset",
    timeZone: "America/Sao_paulo",
  });
  today?.setSeconds(0, 0);
  const twoMonths = new Date();
  twoMonths?.toLocaleString("en-US", {
    timeZoneName: "longOffset",
    timeZone: "America/Sao_paulo",
  });
  twoMonths?.setMonth(today.getMonth() - 2);
  twoMonths?.setHours(0, 0, 0, 0);
  const defaultDateInicial = new Date();
  defaultDateInicial.setMonth(today.getMonth() - 1);
  defaultDateInicial?.setHours(0, 0, 0, 0);
  const defaultDateFinal = new Date();
  defaultDateFinal?.setHours(0, 0, 0, 0);
  const [sb, clienteInfo, si] = useOutletContext();
  const [snackbar, setSnackbar] = useState(null);
  const [excendete, setExcedente] = useState(false);
  const [Null, setNull] = useState(false);
  const [invalidDateInicial, setInvalidDateInicial] = useState(false);
  const [invalidDateFinal, setInvalidDateFinal] = useState(false);
  const [percentil, setPercentil] = useState({});
  const [valorExecedente, setValorExcedente] = useState();
  const [selectedMonth, setSelectedMonth] = useState("actual");
  //const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    titulo: "",
    status: 0,
    tipo: 0,
    dataInicial: "",
    dataFinal: "",
    clienteId: clienteInfo?.id,
    tarifacaoExcedente: {
      status: false,
      valor: "0",
      valorExcedente: "0",
      limite: "0",
    },
  });

  const theme = useTheme();
  const styles = useStyle(theme);

  useEffect(() => {
    handlePercentil();
    setFormData({
      titulo: "",
      status: 0,
      tipo: 0,
      dataInicial: dayjs(defaultDateInicial),
      dataFinal: defaultDateFinal,
      clienteId: clienteInfo?.id,
      tarifacaoExcedente: {
        status: false,
        valor: "0,00",
        valorExcedente: "0",
        limite: "0",
      },
    });
  }, [clienteInfo]);
  useEffect(() => {
    setValorExcedente(
      (
        (parseFloat(formData?.tarifacaoExcedente?.valorExcedente) *
          convertNumero(formData?.tarifacaoExcedente.valor)) /
          100 || 0
      )
        ?.toFixed(2)
        ?.replace(".", ",")
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    );
  }, [
    formData?.tarifacaoExcedente.valor,
    formData?.tarifacaoExcedente.valorExcedente,
  ]);

  useEffect(() => {
    if (selectedMonth === "last") {
      let final_date = new Date();
      let initial_date = new Date();
      initial_date = dayjs(initial_date)
        .subtract(1, "month")
        .date(1)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0);
      final_date = dayjs(final_date)
        .date(1)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0);
      setFormData((prev) => ({
        ...prev,
        dataInicial: initial_date.toISOString(),
        dataFinal: final_date.toISOString(),
      }));
    } else {
      let final_date = new Date();
      let initial_date = new Date();
      initial_date = dayjs(initial_date)
        .date(1)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0);
      final_date = dayjs(final_date)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0);

      setFormData((prev) => ({
        ...prev,
        dataInicial: initial_date.toISOString(),
        dataFinal: final_date.toISOString(),
      }));
    }
  }, [selectedMonth]);

  const handleExedente = (status) => {
    setExcedente(status);
    setFormData((prev) => ({
      ...prev,
      tarifacaoExcedente: { ...prev?.tarifacaoExcedente, status: status },
    }));
    if (!status) {
      setFormData((prev) => ({
        ...prev,
        tarifacaoExcedente: {
          ...prev?.tarifacaoExcedente,
          limite: "0",
          valorExcedente: "0",
        },
      }));
    }
  };
  const handleValor = (e) => {
    setFormData((prev) => ({
      ...prev,
      tarifacaoExcedente: {
        ...prev?.tarifacaoExcedente,
        valor: mascara(e?.target?.value),
      },
    }));
  };

  const handleValorExcedente = (e) => {
    setFormData((prev) => ({
      ...prev,
      tarifacaoExcedente: {
        ...prev?.tarifacaoExcedente,
        valorExcedente: e?.target?.value?.replace(/\D/g, ""),
      },
    }));
  };

  const handleLimiteTráfego = (e) => {
    setFormData((prev) => ({
      ...prev,
      tarifacaoExcedente: {
        ...prev?.tarifacaoExcedente,
        limite: e?.target?.value?.replace(/\D/g, ""),
      },
    }));
  };

  const mascara = (value) => {
    const valorInteiro = value?.replace(/\D/g, "");
    const valorFormatado = (valorInteiro / 100)
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return valorFormatado;
  };

  const convertNumero = (value) => {
    return parseFloat(value?.replace(/\./g, "")?.replace(",", "."));
  };

  const handlePercentil = async () => {
    try {
      const response = await api.post("/interno/percentilCliente", {
        clienteId: clienteInfo?.id,
      });

      setPercentil(response?.data);
    } catch (erro) {
      console.error(erro);
    } finally {
    }
  };

  const handleDados = async () => {
    if (
      !formData?.titulo?.trim() ||
      (formData?.tarifacaoExcedente?.status &&
        (!formData?.tarifacaoExcedente?.limite ||
          !formData?.tarifacaoExcedente?.valorExcedente ||
          !formData?.tarifacaoExcedente?.valor)) ||
      !formData?.dataFinal ||
      !formData?.dataFinal ||
      invalidDateFinal ||
      invalidDateInicial
    ) {
      setNull(true);
    } else {
      setLoading(true);

      try {
        const response = await api.post("/interno/relatorio", {
          clienteId: clienteInfo?.id,
          initial_date: formData?.dataInicial,
          final_date: formData?.dataFinal,
          tarifacao: formData?.tarifacaoExcedente?.valor,
        });

        const relatorio = {
          titulo: formData?.titulo,
          client: response?.data?.client,
          status: 0,
          tipo: 1,
          dataInicial: formData?.dataInicial,
          dataFinal: formData?.dataFinal,
          hideCharts: false,
          percentil: percentil?.percentil,
          analysis: response?.data?.analysis,
          interfaces: response?.data?.interfaces,
          tarifacaoExcedente: {
            status: formData?.tarifacaoExcedente?.status,
            valor: convertNumero(formData?.tarifacaoExcedente?.valor),
            valorExcedente:
              convertNumero(valorExecedente) +
              convertNumero(formData?.tarifacaoExcedente?.valor),
            limite: parseInt(formData?.tarifacaoExcedente?.limite),
          },
        };

        const MyDoc = <PageTemplateEasyReport relatorio={relatorio} />;
        let blob = await pdf(MyDoc).toBlob();
        saveAs(blob, `relatório ${relatorio?.client}.pdf`);

        setSnackbar({
          children: "Gerado relatório",
          severity: "success",
        });
        setNull(false);
      } catch (error) {
        let dataError = error?.response?.data;
        if (dataError) {
          setSnackbar({
            children: dataError?.message,
            severity: "error",
          });
        } else {
          setSnackbar({
            children: "Erro ao tentar gerar relatório",
            severity: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box sx={styles.container}>
      <Paper
        sx={styles.containerPaper}
        elevation={3}
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
      >
        <Typography variant="mySubtitle">Relatório</Typography>
        <Paper variant="outlined" sx={styles.paper}>
          <Stack direction={"column"}>
            <Typography sx={styles.subtitle} variant="subtitle2">
              Título do relatório
            </Typography>
            <TextField
              size="small"
              label="Título"
              variant="filled"
              autoComplete="off"
              value={formData?.titulo}
              helperText={
                !formData?.titulo?.trim() && Null
                  ? "Necessário informar um título"
                  : ""
              }
              disabled={loading}
              error={!formData?.titulo?.trim() && Null}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, titulo: e.target.value }))
              }
            />
          </Stack>
          <Divider sx={styles.divider}></Divider>
          <Box sx={styles.containerExcedente}>
            <FormControl>
              <Typography>Cobrança por excedente?</Typography>
              <RadioGroup
                defaultValue="small"
                name="radio-buttons-group"
                value={excendete}
                sx={styles.radioGrupo}
              >
                <Radio
                  value={false}
                  label="Nao"
                  size="md"
                  onChange={() => handleExedente(false)}
                  defaultChecked
                  disabled={loading}
                />
                Não
                <Radio
                  value={true}
                  label="Sim"
                  size="md"
                  onChange={() => handleExedente(true)}
                  disabled={loading}
                />
                Sim
              </RadioGroup>
            </FormControl>
          </Box>

          <Typography sx={styles.subtitle} variant="subtitle2">
            Adicionar valor por Mbps
          </Typography>

          <Box sx={styles.stack}>
            <Box sx={styles.containerCheck}>
              <FormControl
                error={!formData?.tarifacaoExcedente?.valor && Null}
                size="small"
                variant="filled"
                sx={styles.valorMbps}
              >
                <InputLabel>Valor por Mbps</InputLabel>
                <FilledInput
                  value={formData?.tarifacaoExcedente?.valor}
                  onChange={handleValor}
                  startAdornment={
                    <InputAdornment position="start">R$</InputAdornment>
                  }
                  disabled={loading}
                />
              </FormControl>
            </Box>
            {excendete && (
              <>
                <FormControl
                  size="small"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  <InputLabel
                    error={!formData?.tarifacaoExcedente?.limite && Null}
                    htmlFor="filled-adornment-amount"
                  >
                    Limite de tráfego
                  </InputLabel>
                  <FilledInput
                    value={formData?.tarifacaoExcedente?.limite}
                    onChange={handleLimiteTráfego}
                    disabled={loading}
                  />
                  <FormHelperText id="filled-weight-helper-text">
                    Em Mbps
                  </FormHelperText>
                </FormControl>
                <FormControl
                  size="small"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  <InputLabel
                    error={
                      !formData?.tarifacaoExcedente?.valorExcedente && Null
                    }
                    htmlFor="filled-adornment-amount"
                  >
                    Excedente em %
                  </InputLabel>
                  <FilledInput
                    value={formData?.tarifacaoExcedente?.valorExcedente}
                    onChange={handleValorExcedente}
                    disabled={loading}
                  />
                  <FormHelperText id="filled-weight-helper-text">
                    R$ {valorExecedente}
                  </FormHelperText>
                </FormControl>
              </>
            )}
          </Box>

          <Divider sx={styles.divider}></Divider>
          <Typography sx={styles.subtitle} variant="subtitle2">
            Intervalo de coleta
          </Typography>

          <Box sx={styles.containerDate}>
            <FormControl sx={styles.formControl} size="small">
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={selectedMonth || ""}
                onChange={(e) => {
                  setSelectedMonth(e?.target?.value);
                }}
                disabled={loading}
              >
                <MenuItem value={"actual"}>
                  {mesAtual[0]?.toUpperCase() + mesAtual?.substring(1)}
                </MenuItem>
                <MenuItem value={"last"}>
                  {mesPassado[0]?.toUpperCase() + mesPassado?.substring(1)}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Divider sx={styles.divider}></Divider>
          <Stack spacing={2} alignItems={"end"}>
            <LoadingButton
              loading={loading}
              onClick={handleDados}
              variant="contained"
              size="medium"
            >
              Gerar relatório
            </LoadingButton>
          </Stack>
        </Paper>
      </Paper>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
