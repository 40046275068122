import { Add, Delete } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
  useTheme,
} from "@mui/material";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import InternoModalDeleteCliente from "../../../../../components/internoModalDeleteCliente";
import api from "../../../../../services/api";
import axios from "axios";
import { LoadingButton } from "@mui/lab";

export default function ASN(params) {
  const { setChanged = () => {}, clienteInfo } = params;
  const [editAsn, setEditAsn] = useState(false);
  const [asns, setAsns] = useState([]);
  const [asnsManipulation, setAsnsManipulation] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [errors, setErrors] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);

  useEffect(() => {
    if (asns) {
      setAsnsManipulation(JSON.parse(JSON.stringify(asns)));
    }
  }, [asns]);

  useEffect(() => {
    handleApiGetAsns();
  }, [clienteInfo]);

  useEffect(() => {
    if (!openDeleteModal) {
      setLoadingButton(false);
    }
  }, [openDeleteModal]);

  async function handleApiGetAsns() {
    setLoading(true);
    try {
      const response = await api.post("/interno/juniper/asn", {
        clienteId: clienteInfo?.id,
      });

      setAsns(response.data);
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se buscar os asns"
        } `,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleApiUpdateAsns() {
    try {
      setDeleteLoad(true);
      const response = await api.post("/interno/juniper/edit/asn", {
        asns: asnsManipulation,
        clienteId: clienteInfo?.id,
      });
      setAsns(response.data);
      setEditAsn(false);
      setSnackbar({
        children: "Asns atualizados com sucesso",
        severity: "success",
      });
      setChanged(false);
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível atualizar os asns"
        } `,
        severity: "error",
      });
    } finally {
      setDeleteLoad(false);
      setOpenDeleteModal(false);
      setLoadingButton(false);
    }
  }

  async function validarAs(as, index) {
    try {
      if (as?.trim() === "") {
        throw new Error("ASN não encontrado");
      }
      const response = await axios.get(
        "https://stat.ripe.net/data/as-overview/data.json",
        {
          params: {
            resource: as,
          },
        }
      );

      if (!response?.data?.data?.announced) {
        throw new Error("ASN não encontrado");
      }
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        [index]:
          error?.message === "ASN não encontrado"
            ? error?.message
            : error?.message === "ASN é obrigatório"
            ? error?.message
            : "ASN inválido",
      }));
      return error?.message;
    }
  }

  function deleteAsn(index) {
    setChanged(true);
    const array = [...asnsManipulation];
    array.splice(index, 1);
    setAsnsManipulation(array);
  }

  function addComunidade() {
    const array = [...asnsManipulation];
    array.push({ id: parseInt(Math.random() * 1000000), asn: "" });
    setAsnsManipulation(array);
  }

  async function handleSave() {
    setLoadingButton(true);
    const asnManiputaionId = asnsManipulation.map((com) => com.id);
    const asnRemovidos = asns.map((com) => !asnManiputaionId.includes(com.id));

    if (asnRemovidos.includes(true)) {
      setOpenDeleteModal(true);
    } else {
      const newErrors = {};

      asnsManipulation.forEach(async (asn, index) => {
        if (asn?.asn?.trim() === "") {
          newErrors[index] = true;
          setErrors((prev) => ({
            ...prev,
            [index]: "ASN é obrigatório",
          }));
          setLoadingButton(false);
        } else {
          const isValid = await validarAs(asn?.asn, index);

          if (isValid && asn?.asn?.trim() !== "") {
            newErrors[index] = true;
            setLoadingButton(false);
          }
        }
        if (
          index === asnsManipulation.length - 1 &&
          Object.keys(newErrors).length === 0
        ) {
          setErrors({});
          handleApiUpdateAsns(asnsManipulation);
        }
      });
    }
  }

  return (
    <Box
      sx={styles.container}
      variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
    >
      {loading ? (
        <Box sx={styles.containerLoading}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={styles.containerTitulo}>
            {editAsn && (
              <Button
                variant="outlined"
                endIcon={<Add />}
                onClick={addComunidade}
              >
                Adicionar ASN
              </Button>
            )}
          </Box>
          <Box sx={styles.containerAsn}>
            <Box sx={styles.BoxASNs}>
              {asnsManipulation.map((asn, index) => (
                <Box key={asn.id} sx={styles.ans}>
                  <TextField
                    id={`as-${index}`}
                    name="as"
                    variant="filled"
                    label="ASN"
                    value={asn?.asn?.trim()}
                    onChange={(e) => {
                      setChanged(true);
                      const array = [...asnsManipulation];
                      array[index].asn = e.target.value;
                      setAsnsManipulation(array);
                      //  setErrors((prev) => ({ ...prev, [index]: "" })); // Limpa o erro ao digitar
                    }}
                    size="small"
                    autoComplete="off"
                    error={!!errors[index]}
                    helperText={errors[index] ? "ASN inválido" : ""}
                    /*  onBlur={(e) => handleBlur(e, index)} */
                    disabled={!editAsn}
                    sx={{ width: "250px" }}
                  />
                  {editAsn ? (
                    <IconButton onClick={() => deleteAsn(index)}>
                      <Delete color="primary" />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </Box>
              ))}
            </Box>
            <Box sx={styles.containerEdit}>
              {!editAsn ? (
                <Button
                  size="small"
                  onClick={() => {
                    setEditAsn(true);
                  }}
                >
                  Editar
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={loadingButton}
                    onClick={() => {
                      setChanged(false);
                      setEditAsn(false);
                      setAsnsManipulation(JSON.parse(JSON.stringify(asns)));
                      setErrors({});
                    }}
                  >
                    Cancelar
                  </Button>
                  <LoadingButton
                    loading={loadingButton}
                    variant="contained"
                    size="small"
                    onClick={handleSave}
                  >
                    Salvar
                  </LoadingButton>
                </>
              )}
            </Box>
          </Box>
        </>
      )}

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        load={deleteLoad}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={() => {
          handleApiUpdateAsns(asnsManipulation);
        }}
        severity={"error"}
        contentText={
          "Uma ou mais ASN's foram removidos, se você prosseguir com a operação, as informações serão excluídas permanentemente!"
        }
        confirmText={"CONFIRMAR"}
      />
    </Box>
  );
}
