import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
  Alert,
  Snackbar,
} from "@mui/material";
import useStyles from "./styles";
import loginImg from "../../../assets/imgs/login/datacenter.webp";
import logoComputizeDark from "../../../assets/imgs/login/loginInterno/logocomputizeDark.webp";
import {
  Person2Outlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");

  const [Null, setNull] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertServiceOpen, setAlertServiceOpen] = useState(false);
  const [alertRequestOpen, setAlertRequestOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const navigate = useNavigate();
  const [shrink, setShrink] = useState(false);
  const [passShrink, setPassShrink] = useState(false);

  // limita o keydown no campo senha
  const [enterFlag, setEnterFlag] = useState(false);

  const url = `${
    window?.config?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || ""
  }`;

  const styles = useStyles();

  const theme = useTheme();

  const handleLogin = async () => {
    if (!login || !senha) {
      setNull(true);
      return;
    }

    setLoading(!login || !senha);
    try {
      const response = await axios.post(
        `${url}/login`,
        { login, senha },
        { withCredentials: false }
      );

      if (response?.data?.token) {
        const { perfil, logs, acessoClienteId, ...userData } = response.data;
        localStorage.clear();
        if (response.data.usuario?.status) {
          localStorage.setItem("user", JSON.stringify(userData));
          if (perfil) localStorage.setItem("perfil", JSON.stringify(perfil));
          localStorage.setItem("logs", JSON.stringify(logs));
        }

        if (
          response.data.usuario?.status == true &&
          response.data.perfilAlreadyExists === true &&
          response?.data?.perfil?.telefone
        ) {
          navigate("/interno/dash");
        } else if (
          response.data.usuario?.status == true &&
          (response.data.perfilAlreadyExists === false ||
            !response?.data?.perfil?.telefone)
        ) {
          navigate("/interno/cadastro");
        } else if (
          response.data.usuario?.status == false &&
          response.data.perfilAlreadyExists === true
        ) {
          setAlert(true);
        }
      } else {
        localStorage.clear();
        setAlertOpen(true);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        setAlertOpen(true);
      } else if (error?.response?.status === 429) {
        setAlertRequestOpen(true);
      } else {
        setAlertServiceOpen(true);
      }
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          backgroundImage: `url(${loginImg})`,
          ...styles.boxImg,
          backgroundSize: "cover",
        }}
      />
      <Button
        variant="outlined"
        sx={styles.buttonNavigate}
        onClick={() => {
          navigate("/login");
        }}
        endIcon={<Person2Outlined />}
      >
        CLIENTE
      </Button>

      <Box sx={styles.body}>
        <img src={logoComputizeDark} style={styles.img} alt="logo-computize" />

        <TextField
          InputLabelProps={{ sx: { ml: 4.5 }, shrink }}
          inputProps={{
            sx: { height: "48px", padding: 0 },
          }}
          id="login"
          variant="filled"
          placeholder="Login"
          size="small"
          sx={styles.textfield}
          error={!login && Null}
          helperText={!login && Null ? " Login Inválido" : ""}
          onChange={(event) => {
            setLogin(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              document.getElementById("password").focus();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle color="disabled" sx={{ mb: 2 }} />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          InputLabelProps={{ sx: { ml: 4.5 }, shrink: passShrink }}
          inputProps={{
            sx: { height: "48px", padding: 0 },
          }}
          id="password"
          variant="filled"
          placeholder="Senha"
          size="small"
          type={showPassword ? "text" : "password"}
          sx={styles.textfield}
          autoComplete="off"
          error={!senha && Null}
          helperText={!senha && Null ? " Senha Inválida" : ""}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !enterFlag) {
              setEnterFlag(true);
              handleLogin();
            }
          }}
          onKeyUp={(event) => {
            setEnterFlag(false);
          }}
          onChange={(event) => {
            setSenha(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon color="disabled" sx={{ mb: 2 }} />
              </InputAdornment>
            ),

            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="disabled"
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  onMouseDown={(event) => {
                    event.preventDefault();
                  }}
                >
                  {showPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={styles.boxRecover}>
          {/* <Typography>Esqueceu a senha?</Typography> */}
          <Button
            sx={styles.buttonRecover}
            onClick={() => {
              navigate("/interno/redefinirSenha");
            }}
          >
            Esqueceu a senha ?
          </Button>
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={styles.button}
          onClick={handleLogin}
          disabled={loading}
        >
          ENTRAR
        </Button>
      </Box>

      <Snackbar
        autoHideDuration={6000}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Erro: senha ou login incorreto</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={6000}
        open={alert}
        onClose={() => {
          setAlert(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Usuário desativado</Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={6000}
        open={alertServiceOpen}
        onClose={() => {
          setAlertServiceOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">
          Erro: Falha ao tentar se conectar ao Servidor
        </Alert>
      </Snackbar>
      <Snackbar
        autoHideDuration={6000}
        open={alertRequestOpen}
        onClose={() => {
          setAlertRequestOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">
          Erro: Muitas tentativas incorretas. Por favor aguarde 5 minutos
        </Alert>
      </Snackbar>
    </Box>
  );
}
