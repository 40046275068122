import { useTheme } from "@mui/system";
import useStyles from "./styles";
import "dayjs/locale/pt-br";
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import LineChart from "./line";
import { useEffect } from "react";
import { MoneyOffOutlined, WarningAmber } from "@mui/icons-material";
import apiCliente from "../../../../services/apiCliente";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/pt-br"; // Importa a localidade para português

dayjs.extend(localizedFormat);
dayjs.locale("pt-br"); // Define a localidade como português

export default function PercentilCliente() {
  const [snackbar, setSnackbar] = useState(null);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("actual");

  // Meses do seletor
  const mesAtual = dayjs().format("MMMM"); // Nome do mês atual
  const mesPassado = dayjs().subtract(1, "month").format("MMMM"); // Nome do mês passado

  // Informações do cliente
  const cliente =
    JSON.parse(localStorage.getItem("userCliente"))?.usuarioCliente || "";

  // Estilização
  const theme = useTheme();
  const styles = useStyles(theme);

  // Ações ao carregar a página
  useEffect(() => {
    setSelectedMonth("actual");
    getPercentil();
  }, []);

  // Solicita novos dados ao alterar o período
  useEffect(() => {
    getPercentil();
  }, [selectedMonth]);

  // Recebe dados
  const getPercentil = async () => {
    try {
      setLoading(true);
      const response = await apiCliente.post(
        "/cliente/analiseTraficPercentil",
        {
          clienteId: cliente?.clienteId,
          previousMonth: Boolean(selectedMonth !== "actual"),
        }
      );
      if (!response?.data) {
        throw new Error();
      }
      setData(response?.data);

      setSnackbar({
        children: "Valores de tráfego recebidos com sucesso!",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.response?.data?.error ||
          "Não foi possível se buscar valores de tráfego"
        } `,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // Define horas e minutos a partir do tempo excedido
  const disponibilityHours = Math.floor(
    (data?.tempoMaximo - data?.tempoExedente) / 60 > 0
      ? (data?.tempoMaximo - data?.tempoExedente) / 60
      : 0
  );
  const disponibilityMinutes = parseInt(
    (data?.tempoMaximo - data?.tempoExedente) / 60 > 0
      ? Math.floor(data?.tempoMaximo - data?.tempoExedente) % 60
      : 0
  );

  const exceededHours =
    data?.tempoExedente > data?.tempoMaximo
      ? Math.floor((data?.tempoExedente - data?.tempoMaximo) / 60)
      : 0;

  const exceededMinutes =
    data?.tempoExedente > data?.tempoMaximo
      ? parseInt(data?.tempoExedente % 60)
      : 0;

  // Escala de exibição de Tráfego
  const maxValuebps = Math.max(
    ...(data?.traficoTotal?.map((d) => d?.value || 0) || [0])
  );

  // Conversão de dados
  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 B";
    const k = 1000;
    const sizes = ["bps", "Kbps", "Mbps", "Gbps", "Tbps", "Pbps", "Ebps"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  return (
    <Box sx={styles.container}>
      {loading && (
        <Box sx={styles.loading}>
          <CircularProgress size={100} thickness={2.5} />
        </Box>
      )}
      {!loading && (
        <Box sx={styles.papersContainer}>
          <Box sx={styles.filterBox}>
            <Typography sx={styles.filterTypograph}>
              Intervalo de tempo
            </Typography>
            <FormControl sx={styles.formControl} size="small">
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={selectedMonth || ""}
                onChange={(e) => {
                  setSelectedMonth(e?.target?.value);
                }}
              >
                <MenuItem value={"actual"}>
                  {mesAtual[0]?.toUpperCase() + mesAtual?.substring(1)}
                </MenuItem>
                <MenuItem value={"last"}>
                  {mesPassado[0]?.toUpperCase() + mesPassado?.substring(1)}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Paper sx={{ ...styles.paper, height: "auto" }}>
            <Box sx={styles.trafficInfo}>
              <Box sx={styles.itemCampo}>
                <Typography sx={styles.itemTitle}>
                  <MoneyOffOutlined color="secondary" sx={styles.iconTitle} />
                  Valor base
                </Typography>
                <Typography sx={styles.itemText}>
                  <Typography sx={styles.typography}>R$</Typography>
                  {data?.tarifacaoFixa?.toFixed(2)}
                  <Typography sx={styles.typography}> por Mbps</Typography>
                </Typography>
              </Box>

              <Box sx={styles.itemCampo}>
                <Typography sx={styles.itemTitle}>
                  <WarningAmber color="secondary" sx={styles.iconTitle} />
                  Commit
                </Typography>

                <Typography sx={styles.itemText}>
                  {(data?.valorExcedente &&
                    formatBytes(data?.valorExcedente)?.split(" ")[0]) ||
                    ""}{" "}
                  <Typography sx={styles.typography}>
                    {(data?.valorExcedente &&
                      formatBytes(data?.valorExcedente)?.split(" ")[1]) ||
                      ""}
                  </Typography>
                </Typography>
              </Box>

              <Box sx={styles.itemCampo}>
                <Typography sx={styles.itemTitle}>
                  <MoneyOffOutlined color="secondary" sx={styles.iconTitle} />
                  Valor do excedente
                </Typography>
                <Typography sx={styles.itemText}>
                  <Typography sx={styles.typography}>R$</Typography>
                  {data?.tarifacaoExcedente?.toFixed(2)}
                  <Typography sx={styles.typography}> por Mbps</Typography>
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Box sx={styles.progressHeader}>
                  <Typography sx={styles.textLimite}>
                    Limite de Tempo Disponível:
                  </Typography>
                  <Box sx={styles.progressHeaderContent}>
                    <Typography sx={styles.textHorasMinutos}>
                      {disponibilityHours + " Horas e"}
                    </Typography>
                    <Typography sx={styles.textHorasMinutos}>
                      {disponibilityMinutes + " Minutos"}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={styles.progressHeader}>
                  <Typography
                    sx={
                      exceededHours > 2
                        ? {
                            ...styles.textLimite,
                            ...styles.animacaoLimiteExcedido,
                          }
                        : styles.textLimite
                    }
                  >
                    Limite de Tráfego Excedido:
                  </Typography>
                  <Box sx={styles.progressHeaderContent}>
                    <Typography
                      sx={
                        exceededHours > 2
                          ? {
                              ...styles.textHorasMinutos,
                              ...styles.animacaoLimiteExcedido,
                            }
                          : styles.textHorasMinutos
                      }
                    >
                      {/* {exceededHours + " Horas e"} */}
                      {formatBytes(data?.trafegoExcedido)}
                    </Typography>
                    {/* <Typography
                      sx={
                        exceededHours > 2
                          ? {
                              ...styles.textHorasMinutos,
                              ...styles.animacaoLimiteExcedido,
                            }
                          : styles.textHorasMinutos
                      }
                    >
                      {exceededMinutes + " Minutos"}
                    </Typography> */}
                  </Box>
                </Box>

                <Box sx={styles.progressContainer}>
                  <Box sx={styles.progressCaptionContainer}>
                    <Typography sx={styles.progressCaption}>0 Horas</Typography>

                    <Typography sx={styles.progressCaption}>
                      {data?.tempoMaximo / 60 || 0} Horas
                    </Typography>
                  </Box>
                  <Tooltip
                    title={
                      data?.tempoExedente && data?.tempoMaximo
                        ? `Limite de Tráfego Consumido: ${
                            (data?.tempoExedente / data?.tempoMaximo).toFixed(
                              4
                            ) * 100
                          }%`
                        : ""
                    }
                    placement="top"
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                      width: "60px",
                    }}
                  >
                    <LinearProgress
                      variant="determinate"
                      value={
                        (data?.tempoExedente / data?.tempoMaximo) * 100 < 100
                          ? (data?.tempoExedente / data?.tempoMaximo) * 100
                          : 0
                      }
                      color={
                        data?.tempoExedente && data?.tempoMaximo
                          ? (data?.tempoExedente / data?.tempoMaximo) * 100 <
                            100
                            ? "primary"
                            : "secondary"
                          : "primary"
                      }
                      sx={styles.linearPregress}
                    />
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Paper>

          <Paper sx={styles.paper}>
            <LineChart
              percentil={data?.percentil}
              percentile95={data?.traficoPercentil?.split(" ")[0]}
              tituloLimite={data?.valorExcedente || 0}
              valorLimite={data?.valorExcedente || 0}
              maxValuebps={maxValuebps}
              data={
                data?.traficoTotal?.map((traffic, index) => {
                  let result = {};

                  (result = {
                    date: dayjs.unix(traffic?.clock).format("DD/MM"),
                    cleanTraffic: traffic?.value,
                  }) || (result = { date: 0, cleanTraffic: 0 });

                  return result;
                }) || []
              }
            />
          </Paper>
        </Box>
      )}

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
