const styles = (props) => ({
  container: {
    padding: "21px 0 0 0",
    display: "flex",
    flexDirection: "column",
    gap: "13px",
    width: "100%",
  },
  containerTitulo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  containerAsn: {
    padding: "21px 0 0 0",
    display: "flex",
    flexDirection: "column",
    gap: "13px",
    bgcolor: "transparent",
  },
  BoxASNs: {
    display: "flex",
    flexDirection: "row",
    gap: "13px",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  ans: {
    display: "flex",
    alignItems: "center",
  },
  containerEdit: {
    display: "flex",
    flexDirection: "row",
    gap: "13px",
    justifyContent: "flex-end",
  },
  containerLoading: {
    display: "flex",
    justifyContent: "center",
  },
});

export default styles;
