import { React, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  TextField,
  IconButton,
  Button,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useTheme,
  Divider,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
} from "@mui/material";
import { Delete, ExpandMore, MoreVert } from "@mui/icons-material";
import validarIp from "../../../../utils/validadorIp";
import useStyles from "./styles";
import InternoModalDeleteCliente from "../../../../components/internoModalDeleteCliente";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import apiCliente from "../../../../services/apiCliente";

export default function PrefixosCliente(params) {
  const theme = useTheme();
  const [snackbar, setSnackbar] = useState(null);
  const [valueTabsPrefixoPendente, setValueTabsPrefixoPendente] = useState("1");
  const [valueTabsPrefixoAdicionados, setValueTabsPrefixoAdicionados] =
    useState("1");

  const [loading, setLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [prefixos, setPrefixos] = useState([]);
  const [clientId, setClientId] = useState("");
  const [prefixoPendete, setPrefixoPendente] = useState({});
  const [prefixoConcluido, setPrefixoConcluido] = useState({});
  const [idDelete, setIdDelete] = useState([]);
  const [errors, setErrors] = useState({});
  const [deleteLoad, setDeleteLoad] = useState(false);
  const styles = useStyles(theme);
  const logs = JSON.parse(localStorage.getItem("logs"));
  const clienteInfo = JSON.parse(localStorage.getItem("userCliente"));

  const deleteBody =
    "Um ou mais prefixos foram removidos, se você prosseguir com a operação, as informações serão excluídas permanentemente!";
  const confirmDelete = "CONFIRMAR";

  //Atualizar o useState prefixoPendente quando ocorrer chamada da API para renderizar todos os prefixos
  useEffect(() => {
    setPrefixoPendente(prefixos);
  }, [prefixos]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("userCliente"));
    setClientId(data?.usuarioCliente?.clienteId?.toString());
    getPrefixos();
    getAllPrefixos();
  }, []);

  //RECEBER TODOS OS PREFIXOS (CONCLUIDOS)
  async function getAllPrefixos() {
    setLoading(true);
    try {
      const response = await apiCliente.post("/find/cliente/prefixos", {
        clienteId: JSON.parse(
          localStorage.getItem("userCliente")
        )?.usuarioCliente?.clienteId?.toString(),
      });

      setPrefixoConcluido(response?.data);
      setLoading(false);
      setOpenDeleteModal(false);
    } catch (e) {
      console.error("erro ao buscar prefixos ss", e);
    }
  }

  //RECEBER PREFIXOS PENDENTES
  async function getPrefixos() {
    setLoading(true);

    try {
      const response = await apiCliente.post("/solicitacoes/get/prefixos", {
        clienteId: JSON.parse(
          localStorage.getItem("userCliente")
        )?.usuarioCliente?.clienteId?.toString(),
      });

      setPrefixos(response?.data);
      setOpenDeleteModal(false);
      setLoading(false);
    } catch (e) {
      console.error("erro ao buscar prefixos ss", e);
    }
  }

  const handleChangeTabsPrefixo = (event, newValue) => {
    setValueTabsPrefixoPendente(newValue);
  };
  const handleChangeTabsPrefixoAdicionados = (event, newValue) => {
    setValueTabsPrefixoAdicionados(newValue);
  };

  const [isEditing, setIsEditing] = useState(false);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  //CANCELAR ATUALIZAÇÃO DE PREFIXOS
  const handleCancelar = () => {
    setIsEditing(false);
    //RETORNAR OS PREFIXOS PARA ANTES DA EDIÇAO
    setPrefixoPendente(prefixos);
    setIdDelete("");
  };

  //FUNCAO PARA RECEBER OS DADOS DE PREFIXOS QUE ESTAO SENDO ALTERADOS NO MOMENTO
  const handlePrefixChange = (id, newPrefixo) => {
    //FILTRAR PREFIXOS PARA REALIZAR O UPDATE
    const updatedPrefixos = prefixoPendete?.map(
      (prefixo) =>
        prefixo.id === id ? { ...prefixo, prefixo: newPrefixo } : prefixo //LOGICA: SE FOR IDENTIFICADO O ID DO PREFIXO, ALTERAR O PREFIXO COM ID IGUAL. CASO CONTRARIO, CRIAR NOVO PREFIXO
    );
    //PREPARAR ATUALIZAÇÃO DE PREFIXO
    setPrefixoPendente(updatedPrefixos);
  };

  const handleDeleteId = (idSelect) => {
    setIdDelete((id) => [...id, idSelect]);
  };

  useEffect(() => {}, [idDelete]);

  //FUNCAO PARA DELETAR PREFIXOS
  const handleDeletePrefixo = async (id) => {
    setPrefixoPendente(prefixoPendete?.filter((prefixo) => prefixo.id !== id));
  };

  //FUNCAO PARA ADICIONAR NOVO IMPUT PARA IPV4
  const handleAddPrefixoIpv4 = () => {
    const newPrefixo = {
      id: (Math.floor(Math.random() * (1000 - 1 + 1)) + 1).toString(),
      prefixo: "",
      status: 0,
      tipo: 0,
    };

    setPrefixoPendente([...prefixoPendete, newPrefixo]);
  };
  //FUNCAO PARA ADICIONAR NOVO IMPUT PARA IPV4
  const handleAddPrefixoIPv6 = () => {
    const newPrefixo = {
      id: (Math.floor(Math.random() * (1000 - 1 + 1)) + 1).toString(),
      prefixo: "",
      status: 0,
      tipo: 1,
    };
    setPrefixoPendente([...prefixoPendete, newPrefixo]);
  };

  const handleConfirmUpate = () => {
    const result = prefixos?.filter((prefixo) =>
      idDelete?.includes(prefixo?.id)
    );

    if (result?.length > 0) {
      setOpenDeleteModal(true);
    } else {
      handleUpdate();
    }
  };

  //FUNCAO PARA REALIZAR ATUALIZAÇÃO DE PREFIXO NO BACKEND

  const handleUpdate = async () => {
    let isValid = true;

    // Verifica todos os prefixos IPv4
    prefixoPendete
      ?.filter((pref) => pref?.tipo === 0)
      ?.forEach((pref) => {
        const valid = handleBlurIpv4(pref?.prefixo, "prefixos", pref?.id);
        if (!valid) isValid = false;
      });

    // Verifica todos os prefixos IPv6
    prefixoPendete
      ?.filter((pref) => pref?.tipo === 1)
      ?.forEach((pref) => {
        const valid = handleBlurIpv6(pref?.prefixo, "prefixos", pref?.id);
        if (!valid) isValid = false;
      });

    if (!isValid) {
      console.error("prefixo inválido");
      return;
    }

    setIsEditing(false);

    //FILTRAR TODOS OS PREFICOS QUE ESTAO COM STATUS PENDENTE E QUE NAO SEJAM VAZIOS PARA REALIZADO ATUALIZAR
    const prefixosAtualizados = prefixoPendete?.filter(
      (pref) => pref?.status === 0 && pref?.prefixo
    );

    try {
      const response = await apiCliente.put("/solicitacoes/update/prefixos", {
        clienteId: clientId,
        prefixos: prefixosAtualizados,
        prefixosDelete: idDelete,
        usuarioClienteId: logs?.usuarioClienteId,
        logsLoginClienteId: logs?.id,
        clienteInfo: clienteInfo?.usuarioCliente,
      });
      setSnackbar({
        children: "Prefixo atualizado com sucesso!",
        severity: "success",
      });

      setPrefixoPendente(response?.data);
      setIdDelete("");
      setPrefixos(response?.data);
      setErrors({});
    } catch {
      console.error("erro ao adicionar prefixo");
    } finally {
      setOpenDeleteModal(false);
      setLoading(false);
    }
  };

  ////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////

  function handleBlurIpv4(value, name, id) {
    if (name === "prefixos") {
      const isValid = validarIp(value, "justIpv4Mask");
      setErrors((prev) => ({ ...prev, [id]: "" }));

      if (isValid === 1) {
        setErrors((prev) => ({ ...prev, [id]: "" }));
        return true;
      } else {
        setErrors((prev) => ({
          ...prev,
          [id]: "IP inválido",
        }));
        return false;
      }
    }
  }

  function handleBlurIpv6(value, name, id) {
    if (name === "prefixos") {
      const isValid = validarIp(value, "justIpv6Mask");
      setErrors((prev) => ({ ...prev, [id]: "" }));

      if (isValid === 1) {
        setErrors((prev) => ({ ...prev, [id]: "" }));
        return true;
      } else {
        setErrors((prev) => ({
          ...prev,
          [id]: "IP inválido",
        }));
        return false;
      }
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={styles.paper}
        variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
        elevation={3}
      >
        <Typography sx={{ fontSize: "21px" }}>Solicitações</Typography>

        <Box>
          <Accordion sx={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={styles.accordionPendente}
            >
              <Typography variant="subtitle1"> Prefixos em análise</Typography>
            </AccordionSummary>
            <Divider variant="fullWidth" />
            <AccordionDetails>
              <TabContext value={valueTabsPrefixoPendente}>
                <TabList
                  onChange={handleChangeTabsPrefixo}
                  aria-label="lab API tabs example"
                  sx={{ width: "100%" }}
                >
                  <Tab label="IPV4" value="1" />
                  <Tab label="IPV6" value="2" />
                  {isEditing && (
                    <Button
                      sx={{ marginLeft: "auto", height: "40px" }}
                      variant="outlined"
                      onClick={
                        valueTabsPrefixoPendente === "1"
                          ? handleAddPrefixoIpv4
                          : handleAddPrefixoIPv6
                      }
                    >
                      Adicionar Prefixo
                    </Button>
                  )}
                </TabList>
                {loading ? (
                  <Box sx={styles.containerLoading}>
                    {" "}
                    <CircularProgress />
                  </Box>
                ) : (
                  <TabPanel value="1">
                    <Grid sx={{ marginTop: "20px" }} container spacing={2}>
                      {Array.isArray(prefixoPendete) &&
                        prefixoPendete
                          ?.filter((data) => data?.tipo === 0)
                          ?.map((item, index) => (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={4}
                              xl={3}
                              sx={styles.grid}
                            >
                              {isEditing ? (
                                <Stack
                                  flexGrow={1}
                                  direction={"row"}
                                  key={item?.id}
                                  sx={styles.stack}
                                >
                                  <TextField
                                    id={"prefixos"}
                                    name="prefixos"
                                    label="Prefixo"
                                    variant="filled"
                                    autoComplete="off"
                                    fullWidth
                                    size="small"
                                    value={item?.prefixo}
                                    onChange={(e) =>
                                      handlePrefixChange(
                                        item?.id,
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) =>
                                      handleBlurIpv4(
                                        e.target.value,
                                        e.target.name,
                                        item?.id
                                      )
                                    }
                                    error={Boolean(errors?.[item?.id])}
                                    helperText={errors?.[item?.id]}
                                    sx={styles.textFieldPrefixos}
                                  />
                                </Stack>
                              ) : (
                                <Stack
                                  flexGrow={1}
                                  direction={"row"}
                                  key={item?.id}
                                  sx={styles.stack}
                                >
                                  <TextField
                                    label="Prefixo"
                                    variant="filled"
                                    fullWidth
                                    size="small"
                                    value={item?.prefixo}
                                    disabled={true}
                                    sx={styles.textFieldPrefixos}
                                  />
                                </Stack>
                              )}

                              {isEditing && (
                                <Box>
                                  <IconButton
                                    onClick={() => {
                                      handleDeletePrefixo(item?.id);

                                      handleDeleteId(item?.id);
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Box>
                              )}
                            </Grid>
                          ))}
                    </Grid>
                    <Box sx={styles.containerEditar}>
                      {isEditing ? (
                        <Box>
                          <Button
                            variant="contained"
                            onClick={handleConfirmUpate}
                          >
                            Atualizar
                          </Button>
                          <Button
                            sx={{ marginLeft: "10px" }}
                            onClick={handleCancelar}
                          >
                            Cancelar
                          </Button>
                        </Box>
                      ) : (
                        <Button variant="contained" onClick={handleEditToggle}>
                          Editar
                        </Button>
                      )}
                    </Box>
                  </TabPanel>
                )}
                {loading ? (
                  <Box sx={styles.containerLoading}>
                    {" "}
                    <CircularProgress />
                  </Box>
                ) : (
                  <TabPanel value="2">
                    <Grid sx={{ marginTop: "20px" }} container spacing={2}>
                      {Array.isArray(prefixoPendete) &&
                        prefixoPendete
                          ?.filter(
                            (data) => data?.status === 0 && data?.tipo === 1
                          )
                          ?.map((item, index) => (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              lg={4}
                              xl={3}
                              sx={{ display: "flex" }}
                            >
                              {isEditing ? (
                                <Stack
                                  flexGrow={1}
                                  direction={"row"}
                                  key={item?.id}
                                  sx={styles.stackEditar}
                                >
                                  <TextField
                                    id={"prefixos"}
                                    name="prefixos"
                                    label="Prefixo"
                                    variant="filled"
                                    autoComplete="off"
                                    fullWidth
                                    size="small"
                                    value={item?.prefixo}
                                    onChange={(e) =>
                                      handlePrefixChange(
                                        item?.id,
                                        e.target.value
                                      )
                                    }
                                    onBlur={(e) =>
                                      handleBlurIpv6(
                                        e.target.value,
                                        e.target.name,
                                        item?.id
                                      )
                                    }
                                    error={Boolean(errors?.[item?.id])}
                                    helperText={errors?.[item?.id]}
                                    sx={styles.textFieldPrefixos}
                                  />
                                </Stack>
                              ) : (
                                <Stack
                                  flexGrow={1}
                                  direction={"row"}
                                  key={item?.id}
                                  sx={styles.stackEditar}
                                >
                                  <TextField
                                    label="Prefixo"
                                    variant="filled"
                                    fullWidth
                                    size="small"
                                    value={item?.prefixo}
                                    disabled={true}
                                    sx={styles.textFieldPrefixos}
                                  />
                                </Stack>
                              )}

                              {isEditing && (
                                <IconButton
                                  onClick={() => {
                                    handleDeletePrefixo(item?.id);

                                    handleDeleteId(item?.id);
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              )}
                            </Grid>
                          ))}
                    </Grid>
                    <Box sx={styles.containerEditar}>
                      {isEditing ? (
                        <Box>
                          <Button
                            variant="contained"
                            onClick={handleConfirmUpate}
                          >
                            Atualizar
                          </Button>
                          <Button
                            sx={{ marginLeft: "10px" }}
                            onClick={handleCancelar}
                          >
                            Cancelar
                          </Button>
                        </Box>
                      ) : (
                        <Button variant="contained" onClick={handleEditToggle}>
                          Editar
                        </Button>
                      )}
                    </Box>
                  </TabPanel>
                )}
              </TabContext>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={styles.accordionConcluido}
            >
              <Typography variant="subtitle1"> Prefixos adicionados</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TabContext value={valueTabsPrefixoAdicionados}>
                <TabList
                  onChange={handleChangeTabsPrefixoAdicionados}
                  aria-label="lab API tabs example"
                  sx={{ width: "100%" }}
                >
                  <Tab label="IPV4" value="1" />
                  <Tab label="IPV6" value="2" />
                </TabList>

                {loading ? (
                  <Box sx={styles.containerLoading}>
                    {" "}
                    <CircularProgress />
                  </Box>
                ) : (
                  <TabPanel value="1">
                    <Grid sx={{ marginTop: "20px" }} container spacing={2}>
                      {Array.isArray(prefixoConcluido) &&
                        prefixoConcluido
                          ?.filter((pref) => pref.tipo === 0)
                          ?.map((prefixo, index) => (
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                              <Stack
                                flexGrow={1}
                                direction={"row"}
                                key={`${prefixo}-${index}`}
                                sx={styles.stack}
                              >
                                <TextField
                                  name={prefixo}
                                  label="Prefixo"
                                  variant="filled"
                                  fullWidth
                                  size="small"
                                  value={prefixo?.prefixo}
                                  disabled={true}
                                  sx={styles.textFieldPrefixos}
                                />
                              </Stack>
                            </Grid>
                          ))}
                    </Grid>
                  </TabPanel>
                )}
                {loading ? (
                  <Box sx={styles.containerLoading}>
                    {" "}
                    <CircularProgress />
                  </Box>
                ) : (
                  <TabPanel value="2">
                    <Grid sx={{ marginTop: "20px" }} container spacing={2}>
                      {Array.isArray(prefixoConcluido) &&
                        prefixoConcluido
                          ?.filter((pref) => pref.tipo === 1)
                          ?.map((prefixo, index) => (
                            <Grid item xs={12} md={6} lg={4} xl={3}>
                              <Stack
                                flexGrow={1}
                                direction={"row"}
                                key={`${prefixo}-${index}`}
                                sx={styles.stack}
                              >
                                <TextField
                                  name={prefixo}
                                  label="Prefixo"
                                  variant="filled"
                                  fullWidth
                                  size="small"
                                  value={prefixo?.prefixo}
                                  disabled={true}
                                  sx={styles.textFieldPrefixos}
                                />
                              </Stack>
                            </Grid>
                          ))}
                    </Grid>
                  </TabPanel>
                )}
              </TabContext>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper>

      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        deleteLoad={deleteLoad}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={handleUpdate}
        severity={"error"}
        contentText={deleteBody}
        confirmText={confirmDelete}
      />

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
